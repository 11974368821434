import React, {FC, CSSProperties, ReactNode} from "react";
import styles from './styles.module.scss'

interface Props {
    onClick?: () => void;
    style?: CSSProperties;
    className?: string;
    type?: 'primary' | 'secondary'
    icon?: ReactNode;
}

const Button: FC<Props> = ({
    onClick,
    style = {},
    className = '',
    type = 'primary',
    icon = <></>
}) => {
    return (
        <span className={`${styles.common} ${styles[type]} ${className}`} style={style} onClick={onClick}>
            {icon}
        </span>
    )
}

export default Button;