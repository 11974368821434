class LocalStorageService {
    private localStorage = window.localStorage

    setItem = (key: string, value: string) => {
        this.localStorage.setItem(key, value)
    }

    removeItem = (key: string) => {
        this.localStorage.removeItem(key)
    }

    getItem = (key: string) => {
        return this.localStorage.getItem(key) || ''
    }

    getKeys = () => {
        return Object.keys(this.localStorage)
    }

    removeItemsByMatch = (matchString: string) => {
        const keysForRemoving: string[] = this.getKeys().filter((key: string) =>
            key.includes(matchString)
        )
        keysForRemoving.forEach((key) => this.localStorage.removeItem(key))
    }
}

export default new LocalStorageService()
