export default {
    oauthResponseType: process.env.REACT_APP_OAUTH_RESPONSE_TYPE || '',

    oauthClientId: process.env.REACT_APP_OAUTH_CLIENT_ID || '',

    oauthRedirectUri: process.env.REACT_APP_OAUTH_REDIRECT_URI || '',

    oauthBaseUrl: process.env.REACT_APP_OAUTH_BASE_LINK || '',

    oauthGrantType: process.env.REACT_APP_OAUTH_GRANT_TYPE || '',

    oauthIdentityProvider: process.env.REACT_APP_OAUTH_IDENTITY_PROVIDER || '',

    oauthScope: process.env.REACT_APP_OAUTH_SCOPE || '',

    useLocal: process.env.REACT_APP_USE_LOCAL_API,

    groundStationApiUri:
        process.env.REACT_APP_USE_LOCAL_API === 'true'
            ? process.env.REACT_APP_LOCAL_GROUND_STATION_URI || ''
            : process.env.REACT_APP_GROUND_STATION_URI || '',

    baseUrl: process.env.REACT_APP_BASE_URL || '',

    webSocketAuthenticationUri: process.env.REACT_APP_WEB_SOCKET_AUTHENTICATION_URI,

    localForageApiCacheName: process.env.REACT_APP_LOCALFORAGE_API_CACHE_NAME || '',

    dynamoRegion: process.env.REACT_APP_DYNAMO_REGION || '',

    dynamoAccessKeyId: process.env.REACT_APP_DYNAMO_ACCESS_KEY_ID || '',

    dynamoSecretAccessKey: process.env.REACT_APP_DYNAMO_SECRET_ACCESS_KEY || '',

    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,

    userPoolId: process.env.REACT_APP_USER_POOL_ID,

    awsRegion: process.env.REACT_APP_AWS_REGION

}
