import { configureStore } from '@reduxjs/toolkit'
import libraryReducer from './library/librarySlice'
import messageSlice from './customHooks/messageSlice'
import confirmationModalSlice from './customHooks/confirmationModalSlice'
import modalSlice from './customHooks/modal'
import orderReducer from './orderSlice'
import commonReducer from './commonSlice'

const store = configureStore({
    reducer: {
        library: libraryReducer,
        order: orderReducer,
        common: commonReducer,
        messageHook: messageSlice,
        confirmationModal: confirmationModalSlice,
        modalHook: modalSlice,
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        serializableCheck: false,
    }),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store;