"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Digital Ground Station REST API
 * Documentation for EnduroSat Digital Ground Station REST API
 *
 * The version of the OpenAPI document: 0.18.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserDataApi = exports.UserDataApiFactory = exports.UserDataApiFp = exports.UserDataApiAxiosParamCreator = exports.SatellitesApi = exports.SatellitesApiFactory = exports.SatellitesApiFp = exports.SatellitesApiAxiosParamCreator = exports.SatelliteTelemetryApi = exports.SatelliteTelemetryApiFactory = exports.SatelliteTelemetryApiFp = exports.SatelliteTelemetryApiAxiosParamCreator = exports.SatelliteTelecommandsApi = exports.SatelliteTelecommandsApiFactory = exports.SatelliteTelecommandsApiFp = exports.SatelliteTelecommandsApiAxiosParamCreator = exports.SatelliteSubsystemsApi = exports.SatelliteSubsystemsApiFactory = exports.SatelliteSubsystemsApiFp = exports.SatelliteSubsystemsApiAxiosParamCreator = exports.SatellitePassesApi = exports.SatellitePassesApiFactory = exports.SatellitePassesApiFp = exports.SatellitePassesApiAxiosParamCreator = exports.SatellitePassPredictionsApi = exports.SatellitePassPredictionsApiFactory = exports.SatellitePassPredictionsApiFp = exports.SatellitePassPredictionsApiAxiosParamCreator = exports.PayloadSlotsApi = exports.PayloadSlotsApiFactory = exports.PayloadSlotsApiFp = exports.PayloadSlotsApiAxiosParamCreator = exports.PayloadFilesApi = exports.PayloadFilesApiFactory = exports.PayloadFilesApiFp = exports.PayloadFilesApiAxiosParamCreator = exports.MissionDatabasesApi = exports.MissionDatabasesApiFactory = exports.MissionDatabasesApiFp = exports.MissionDatabasesApiAxiosParamCreator = exports.GroundStationsApi = exports.GroundStationsApiFactory = exports.GroundStationsApiFp = exports.GroundStationsApiAxiosParamCreator = exports.AuthenticationApi = exports.AuthenticationApiFactory = exports.AuthenticationApiFp = exports.AuthenticationApiAxiosParamCreator = exports.TelecommandStatusEnum = exports.SatellitePassStatusEnum = exports.PassPredictionStatusEnum = exports.ExecutionRuleExecutionRuleTypeEnum = exports.CommunicationChannelFrequencyBandEnum = exports.CommunicationChannelDirectionEnum = exports.ADCSConfigControlModeEnum = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var common_1 = require("./common");
// @ts-ignore
var base_1 = require("./base");
/**
    * @export
    * @enum {string}
    */
var ADCSConfigControlModeEnum;
(function (ADCSConfigControlModeEnum) {
    ADCSConfigControlModeEnum["PWR_UP"] = "PWR_UP";
    ADCSConfigControlModeEnum["NORMAL_DETUMBLING"] = "NORMAL_DETUMBLING";
    ADCSConfigControlModeEnum["Y_THOMSON"] = "Y_THOMSON";
    ADCSConfigControlModeEnum["Y_THOMSON_MEMS_RATE"] = "Y_THOMSON_MEMS_RATE";
    ADCSConfigControlModeEnum["FAST_DETUMBLING"] = "FAST_DETUMBLING";
    ADCSConfigControlModeEnum["VERY_FAST_DETUMBLING"] = "VERY_FAST_DETUMBLING";
    ADCSConfigControlModeEnum["Y_MOMENTUM"] = "Y_MOMENTUM";
    ADCSConfigControlModeEnum["Y_MOMENTUM_FULL_STATE_EKF"] = "Y_MOMENTUM_FULL_STATE_EKF";
    ADCSConfigControlModeEnum["_3AXIS"] = "_3AXIS";
    ADCSConfigControlModeEnum["SUN_TRACKING"] = "SUN_TRACKING";
    ADCSConfigControlModeEnum["TARGET_TRACKING"] = "TARGET_TRACKING";
    ADCSConfigControlModeEnum["NO_CONTROL"] = "NO_CONTROL";
    ADCSConfigControlModeEnum["ATTITUDE_CHANGE"] = "ATTITUDE_CHANGE";
    ADCSConfigControlModeEnum["ORBITAL_CHANGE"] = "ORBITAL_CHANGE";
    ADCSConfigControlModeEnum["XBAND_TARGET_TRACKING"] = "XBAND_TARGET_TRACKING";
})(ADCSConfigControlModeEnum = exports.ADCSConfigControlModeEnum || (exports.ADCSConfigControlModeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var CommunicationChannelDirectionEnum;
(function (CommunicationChannelDirectionEnum) {
    CommunicationChannelDirectionEnum["UPLINK"] = "UPLINK";
    CommunicationChannelDirectionEnum["DOWNLINK"] = "DOWNLINK";
})(CommunicationChannelDirectionEnum = exports.CommunicationChannelDirectionEnum || (exports.CommunicationChannelDirectionEnum = {}));
/**
    * @export
    * @enum {string}
    */
var CommunicationChannelFrequencyBandEnum;
(function (CommunicationChannelFrequencyBandEnum) {
    CommunicationChannelFrequencyBandEnum["UHF"] = "UHF";
    CommunicationChannelFrequencyBandEnum["SBAND"] = "SBAND";
    CommunicationChannelFrequencyBandEnum["XBAND"] = "XBAND";
})(CommunicationChannelFrequencyBandEnum = exports.CommunicationChannelFrequencyBandEnum || (exports.CommunicationChannelFrequencyBandEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ExecutionRuleExecutionRuleTypeEnum;
(function (ExecutionRuleExecutionRuleTypeEnum) {
    ExecutionRuleExecutionRuleTypeEnum["FIRST_OPPORTUNITY"] = "FIRST_OPPORTUNITY";
    ExecutionRuleExecutionRuleTypeEnum["SPECIFIC_PASS"] = "SPECIFIC_PASS";
    ExecutionRuleExecutionRuleTypeEnum["SPECIFIC_GROUND_STATION"] = "SPECIFIC_GROUND_STATION";
    ExecutionRuleExecutionRuleTypeEnum["EVERY_PASS"] = "EVERY_PASS";
    ExecutionRuleExecutionRuleTypeEnum["EVERY_N_DAYS"] = "EVERY_N_DAYS";
    ExecutionRuleExecutionRuleTypeEnum["CONDITIONAL"] = "CONDITIONAL";
})(ExecutionRuleExecutionRuleTypeEnum = exports.ExecutionRuleExecutionRuleTypeEnum || (exports.ExecutionRuleExecutionRuleTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var PassPredictionStatusEnum;
(function (PassPredictionStatusEnum) {
    PassPredictionStatusEnum["PREDICTION"] = "PREDICTION";
    PassPredictionStatusEnum["SCHEDULED"] = "SCHEDULED";
    PassPredictionStatusEnum["IN_PROGRESS"] = "IN_PROGRESS";
    PassPredictionStatusEnum["FINISHED_SUCCESSFULLY"] = "FINISHED_SUCCESSFULLY";
    PassPredictionStatusEnum["FINISHED_WITH_ERRORS"] = "FINISHED_WITH_ERRORS";
    PassPredictionStatusEnum["CANCELED"] = "CANCELED";
    PassPredictionStatusEnum["MISSED"] = "MISSED";
})(PassPredictionStatusEnum = exports.PassPredictionStatusEnum || (exports.PassPredictionStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
var SatellitePassStatusEnum;
(function (SatellitePassStatusEnum) {
    SatellitePassStatusEnum["PREDICTION"] = "PREDICTION";
    SatellitePassStatusEnum["SCHEDULED"] = "SCHEDULED";
    SatellitePassStatusEnum["IN_PROGRESS"] = "IN_PROGRESS";
    SatellitePassStatusEnum["FINISHED_SUCCESSFULLY"] = "FINISHED_SUCCESSFULLY";
    SatellitePassStatusEnum["FINISHED_WITH_ERRORS"] = "FINISHED_WITH_ERRORS";
    SatellitePassStatusEnum["CANCELED"] = "CANCELED";
    SatellitePassStatusEnum["MISSED"] = "MISSED";
})(SatellitePassStatusEnum = exports.SatellitePassStatusEnum || (exports.SatellitePassStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
var TelecommandStatusEnum;
(function (TelecommandStatusEnum) {
    TelecommandStatusEnum["DRAFT"] = "DRAFT";
    TelecommandStatusEnum["SCHEDULED"] = "SCHEDULED";
    TelecommandStatusEnum["REQUESTED"] = "REQUESTED";
    TelecommandStatusEnum["TESTED"] = "TESTED";
    TelecommandStatusEnum["SENDING"] = "SENDING";
    TelecommandStatusEnum["SENT"] = "SENT";
    TelecommandStatusEnum["FAILED_TO_SEND"] = "FAILED_TO_SEND";
    TelecommandStatusEnum["RECEIVED"] = "RECEIVED";
    TelecommandStatusEnum["EXECUTING"] = "EXECUTING";
    TelecommandStatusEnum["SUCCESSFUL"] = "SUCCESSFUL";
    TelecommandStatusEnum["FAILED"] = "FAILED";
    TelecommandStatusEnum["CANCELED"] = "CANCELED";
    TelecommandStatusEnum["TEMPLATE"] = "TEMPLATE";
})(TelecommandStatusEnum = exports.TelecommandStatusEnum || (exports.TelecommandStatusEnum = {}));
/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
exports.AuthenticationApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * This method authenticates a user or refreshes a token.
         * @summary Authenticates a user or refresh a token
         * @param {string} clientId App Client ID
         * @param {string} grantType The oauth2 grant type
         * @param {string} [username] Username - required for grant_type&#x3D;password
         * @param {string} [password] Password - required for grant_type&#x3D;password
         * @param {string} [refreshToken] Refresh Token - required for grant_type&#x3D;refresh_token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticate: function (clientId, grantType, username, password, refreshToken, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'clientId' is not null or undefined
                    common_1.assertParamExists('authenticate', 'clientId', clientId);
                    // verify required parameter 'grantType' is not null or undefined
                    common_1.assertParamExists('authenticate', 'grantType', grantType);
                    localVarPath = "/oauth2/token";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarFormParams = new URLSearchParams();
                    if (username !== undefined) {
                        localVarFormParams.set('username', username);
                    }
                    if (password !== undefined) {
                        localVarFormParams.set('password', password);
                    }
                    if (clientId !== undefined) {
                        localVarFormParams.set('client_id', clientId);
                    }
                    if (refreshToken !== undefined) {
                        localVarFormParams.set('refresh_token', refreshToken);
                    }
                    if (grantType !== undefined) {
                        localVarFormParams.set('grant_type', grantType);
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = localVarFormParams.toString();
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * This method revokes a refreshe token.
         * @summary Revoke a refresh a token
         * @param {string} clientId App Client ID
         * @param {string} token The token to revoke
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeToken: function (clientId, token, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'clientId' is not null or undefined
                    common_1.assertParamExists('revokeToken', 'clientId', clientId);
                    // verify required parameter 'token' is not null or undefined
                    common_1.assertParamExists('revokeToken', 'token', token);
                    localVarPath = "/oauth2/revoke";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarFormParams = new URLSearchParams();
                    if (clientId !== undefined) {
                        localVarFormParams.set('client_id', clientId);
                    }
                    if (token !== undefined) {
                        localVarFormParams.set('token', token);
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = localVarFormParams.toString();
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * AuthenticationApi - functional programming interface
 * @export
 */
exports.AuthenticationApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.AuthenticationApiAxiosParamCreator(configuration);
    return {
        /**
         * This method authenticates a user or refreshes a token.
         * @summary Authenticates a user or refresh a token
         * @param {string} clientId App Client ID
         * @param {string} grantType The oauth2 grant type
         * @param {string} [username] Username - required for grant_type&#x3D;password
         * @param {string} [password] Password - required for grant_type&#x3D;password
         * @param {string} [refreshToken] Refresh Token - required for grant_type&#x3D;refresh_token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticate: function (clientId, grantType, username, password, refreshToken, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.authenticate(clientId, grantType, username, password, refreshToken, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * This method revokes a refreshe token.
         * @summary Revoke a refresh a token
         * @param {string} clientId App Client ID
         * @param {string} token The token to revoke
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeToken: function (clientId, token, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.revokeToken(clientId, token, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * AuthenticationApi - factory interface
 * @export
 */
exports.AuthenticationApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.AuthenticationApiFp(configuration);
    return {
        /**
         * This method authenticates a user or refreshes a token.
         * @summary Authenticates a user or refresh a token
         * @param {string} clientId App Client ID
         * @param {string} grantType The oauth2 grant type
         * @param {string} [username] Username - required for grant_type&#x3D;password
         * @param {string} [password] Password - required for grant_type&#x3D;password
         * @param {string} [refreshToken] Refresh Token - required for grant_type&#x3D;refresh_token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticate: function (clientId, grantType, username, password, refreshToken, options) {
            return localVarFp.authenticate(clientId, grantType, username, password, refreshToken, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * This method revokes a refreshe token.
         * @summary Revoke a refresh a token
         * @param {string} clientId App Client ID
         * @param {string} token The token to revoke
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeToken: function (clientId, token, options) {
            return localVarFp.revokeToken(clientId, token, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
var AuthenticationApi = /** @class */ (function (_super) {
    __extends(AuthenticationApi, _super);
    function AuthenticationApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * This method authenticates a user or refreshes a token.
     * @summary Authenticates a user or refresh a token
     * @param {string} clientId App Client ID
     * @param {string} grantType The oauth2 grant type
     * @param {string} [username] Username - required for grant_type&#x3D;password
     * @param {string} [password] Password - required for grant_type&#x3D;password
     * @param {string} [refreshToken] Refresh Token - required for grant_type&#x3D;refresh_token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    AuthenticationApi.prototype.authenticate = function (clientId, grantType, username, password, refreshToken, options) {
        var _this = this;
        return exports.AuthenticationApiFp(this.configuration).authenticate(clientId, grantType, username, password, refreshToken, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * This method revokes a refreshe token.
     * @summary Revoke a refresh a token
     * @param {string} clientId App Client ID
     * @param {string} token The token to revoke
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    AuthenticationApi.prototype.revokeToken = function (clientId, token, options) {
        var _this = this;
        return exports.AuthenticationApiFp(this.configuration).revokeToken(clientId, token, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return AuthenticationApi;
}(base_1.BaseAPI));
exports.AuthenticationApi = AuthenticationApi;
/**
 * GroundStationsApi - axios parameter creator
 * @export
 */
exports.GroundStationsApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * This method adds an Unavailability Window to a specified Ground Station
         * @summary Add an Unavailability Window to a Ground Station
         * @param {string} groundStationId ID of the Ground Station for which add Unavailability Windows
         * @param {UnavailabilityWindow} unavailabilityWindow
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUnavailabilityWindowsByGroundStationById: function (groundStationId, unavailabilityWindow, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'groundStationId' is not null or undefined
                            common_1.assertParamExists('addUnavailabilityWindowsByGroundStationById', 'groundStationId', groundStationId);
                            // verify required parameter 'unavailabilityWindow' is not null or undefined
                            common_1.assertParamExists('addUnavailabilityWindowsByGroundStationById', 'unavailabilityWindow', unavailabilityWindow);
                            localVarPath = "/ground-stations/{groundStationId}/unavailability-windows"
                                .replace("{" + "groundStationId" + "}", encodeURIComponent(String(groundStationId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication MyEnduroSat required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)];
                        case 1:
                            // authentication MyEnduroSat required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(unavailabilityWindow, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * This method creates a new Ground Stations
         * @summary Create a new Ground Station
         * @param {GroundStation} groundStation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGroundStation: function (groundStation, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'groundStation' is not null or undefined
                            common_1.assertParamExists('createGroundStation', 'groundStation', groundStation);
                            localVarPath = "/ground-stations";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication MyEnduroSat required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)];
                        case 1:
                            // authentication MyEnduroSat required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(groundStation, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * This method deletes a Ground Station with the specified ID
         * @summary Delete a Ground Station by ID
         * @param {string} groundStationId ID of the Ground Station to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGroundStation: function (groundStationId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'groundStationId' is not null or undefined
                            common_1.assertParamExists('deleteGroundStation', 'groundStationId', groundStationId);
                            localVarPath = "/ground-stations/{groundStationId}"
                                .replace("{" + "groundStationId" + "}", encodeURIComponent(String(groundStationId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication MyEnduroSat required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)];
                        case 1:
                            // authentication MyEnduroSat required
                            // oauth required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * This method deletes an Unavailability Window from a specified Ground Station
         * @summary Delete an Unavailability Window from a Ground Station
         * @param {string} groundStationId ID of the Ground Station for which delete Unavailability Windows
         * @param {string} unavailabilityWindowId ID of the Unavailability Window to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUnavailabilityWindow: function (groundStationId, unavailabilityWindowId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'groundStationId' is not null or undefined
                            common_1.assertParamExists('deleteUnavailabilityWindow', 'groundStationId', groundStationId);
                            // verify required parameter 'unavailabilityWindowId' is not null or undefined
                            common_1.assertParamExists('deleteUnavailabilityWindow', 'unavailabilityWindowId', unavailabilityWindowId);
                            localVarPath = "/ground-stations/{groundStationId}/unavailability-windows/{unavailabilityWindowId}"
                                .replace("{" + "groundStationId" + "}", encodeURIComponent(String(groundStationId)))
                                .replace("{" + "unavailabilityWindowId" + "}", encodeURIComponent(String(unavailabilityWindowId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication MyEnduroSat required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)];
                        case 1:
                            // authentication MyEnduroSat required
                            // oauth required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * This method returns a list of all Ground Stations
         * @summary Get all Ground Stations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllGroundStations: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/ground-stations";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication MyEnduroSat required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)];
                        case 1:
                            // authentication MyEnduroSat required
                            // oauth required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * This method returns a Ground Station by ID
         * @summary Get a Ground Station by ID
         * @param {string} groundStationId ID of the Ground Station to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroundStationById: function (groundStationId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'groundStationId' is not null or undefined
                            common_1.assertParamExists('getGroundStationById', 'groundStationId', groundStationId);
                            localVarPath = "/ground-stations/{groundStationId}"
                                .replace("{" + "groundStationId" + "}", encodeURIComponent(String(groundStationId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication MyEnduroSat required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)];
                        case 1:
                            // authentication MyEnduroSat required
                            // oauth required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * This method returns a list of all Unavailability Windows for a specified Ground Station
         * @summary Get all Unavailability Windowss for a Ground Station
         * @param {string} groundStationId ID of the Ground Station for which to return Unavailability Windows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnavailabilityWindowsByGroundStationById: function (groundStationId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'groundStationId' is not null or undefined
                            common_1.assertParamExists('getUnavailabilityWindowsByGroundStationById', 'groundStationId', groundStationId);
                            localVarPath = "/ground-stations/{groundStationId}/unavailability-windows"
                                .replace("{" + "groundStationId" + "}", encodeURIComponent(String(groundStationId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication MyEnduroSat required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)];
                        case 1:
                            // authentication MyEnduroSat required
                            // oauth required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * This method updates a Ground Station by ID
         * @summary Update a Ground Station
         * @param {string} groundStationId ID of the Ground Station to update
         * @param {GroundStation} groundStation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroundStation: function (groundStationId, groundStation, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'groundStationId' is not null or undefined
                            common_1.assertParamExists('updateGroundStation', 'groundStationId', groundStationId);
                            // verify required parameter 'groundStation' is not null or undefined
                            common_1.assertParamExists('updateGroundStation', 'groundStation', groundStation);
                            localVarPath = "/ground-stations/{groundStationId}"
                                .replace("{" + "groundStationId" + "}", encodeURIComponent(String(groundStationId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication MyEnduroSat required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)];
                        case 1:
                            // authentication MyEnduroSat required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(groundStation, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
/**
 * GroundStationsApi - functional programming interface
 * @export
 */
exports.GroundStationsApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.GroundStationsApiAxiosParamCreator(configuration);
    return {
        /**
         * This method adds an Unavailability Window to a specified Ground Station
         * @summary Add an Unavailability Window to a Ground Station
         * @param {string} groundStationId ID of the Ground Station for which add Unavailability Windows
         * @param {UnavailabilityWindow} unavailabilityWindow
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUnavailabilityWindowsByGroundStationById: function (groundStationId, unavailabilityWindow, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.addUnavailabilityWindowsByGroundStationById(groundStationId, unavailabilityWindow, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * This method creates a new Ground Stations
         * @summary Create a new Ground Station
         * @param {GroundStation} groundStation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGroundStation: function (groundStation, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createGroundStation(groundStation, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * This method deletes a Ground Station with the specified ID
         * @summary Delete a Ground Station by ID
         * @param {string} groundStationId ID of the Ground Station to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGroundStation: function (groundStationId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteGroundStation(groundStationId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * This method deletes an Unavailability Window from a specified Ground Station
         * @summary Delete an Unavailability Window from a Ground Station
         * @param {string} groundStationId ID of the Ground Station for which delete Unavailability Windows
         * @param {string} unavailabilityWindowId ID of the Unavailability Window to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUnavailabilityWindow: function (groundStationId, unavailabilityWindowId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteUnavailabilityWindow(groundStationId, unavailabilityWindowId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * This method returns a list of all Ground Stations
         * @summary Get all Ground Stations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllGroundStations: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getAllGroundStations(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * This method returns a Ground Station by ID
         * @summary Get a Ground Station by ID
         * @param {string} groundStationId ID of the Ground Station to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroundStationById: function (groundStationId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getGroundStationById(groundStationId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * This method returns a list of all Unavailability Windows for a specified Ground Station
         * @summary Get all Unavailability Windowss for a Ground Station
         * @param {string} groundStationId ID of the Ground Station for which to return Unavailability Windows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnavailabilityWindowsByGroundStationById: function (groundStationId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getUnavailabilityWindowsByGroundStationById(groundStationId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * This method updates a Ground Station by ID
         * @summary Update a Ground Station
         * @param {string} groundStationId ID of the Ground Station to update
         * @param {GroundStation} groundStation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroundStation: function (groundStationId, groundStation, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateGroundStation(groundStationId, groundStation, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * GroundStationsApi - factory interface
 * @export
 */
exports.GroundStationsApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.GroundStationsApiFp(configuration);
    return {
        /**
         * This method adds an Unavailability Window to a specified Ground Station
         * @summary Add an Unavailability Window to a Ground Station
         * @param {string} groundStationId ID of the Ground Station for which add Unavailability Windows
         * @param {UnavailabilityWindow} unavailabilityWindow
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUnavailabilityWindowsByGroundStationById: function (groundStationId, unavailabilityWindow, options) {
            return localVarFp.addUnavailabilityWindowsByGroundStationById(groundStationId, unavailabilityWindow, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * This method creates a new Ground Stations
         * @summary Create a new Ground Station
         * @param {GroundStation} groundStation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGroundStation: function (groundStation, options) {
            return localVarFp.createGroundStation(groundStation, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * This method deletes a Ground Station with the specified ID
         * @summary Delete a Ground Station by ID
         * @param {string} groundStationId ID of the Ground Station to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGroundStation: function (groundStationId, options) {
            return localVarFp.deleteGroundStation(groundStationId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * This method deletes an Unavailability Window from a specified Ground Station
         * @summary Delete an Unavailability Window from a Ground Station
         * @param {string} groundStationId ID of the Ground Station for which delete Unavailability Windows
         * @param {string} unavailabilityWindowId ID of the Unavailability Window to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUnavailabilityWindow: function (groundStationId, unavailabilityWindowId, options) {
            return localVarFp.deleteUnavailabilityWindow(groundStationId, unavailabilityWindowId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * This method returns a list of all Ground Stations
         * @summary Get all Ground Stations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllGroundStations: function (options) {
            return localVarFp.getAllGroundStations(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * This method returns a Ground Station by ID
         * @summary Get a Ground Station by ID
         * @param {string} groundStationId ID of the Ground Station to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroundStationById: function (groundStationId, options) {
            return localVarFp.getGroundStationById(groundStationId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * This method returns a list of all Unavailability Windows for a specified Ground Station
         * @summary Get all Unavailability Windowss for a Ground Station
         * @param {string} groundStationId ID of the Ground Station for which to return Unavailability Windows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnavailabilityWindowsByGroundStationById: function (groundStationId, options) {
            return localVarFp.getUnavailabilityWindowsByGroundStationById(groundStationId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * This method updates a Ground Station by ID
         * @summary Update a Ground Station
         * @param {string} groundStationId ID of the Ground Station to update
         * @param {GroundStation} groundStation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroundStation: function (groundStationId, groundStation, options) {
            return localVarFp.updateGroundStation(groundStationId, groundStation, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * GroundStationsApi - object-oriented interface
 * @export
 * @class GroundStationsApi
 * @extends {BaseAPI}
 */
var GroundStationsApi = /** @class */ (function (_super) {
    __extends(GroundStationsApi, _super);
    function GroundStationsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * This method adds an Unavailability Window to a specified Ground Station
     * @summary Add an Unavailability Window to a Ground Station
     * @param {string} groundStationId ID of the Ground Station for which add Unavailability Windows
     * @param {UnavailabilityWindow} unavailabilityWindow
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroundStationsApi
     */
    GroundStationsApi.prototype.addUnavailabilityWindowsByGroundStationById = function (groundStationId, unavailabilityWindow, options) {
        var _this = this;
        return exports.GroundStationsApiFp(this.configuration).addUnavailabilityWindowsByGroundStationById(groundStationId, unavailabilityWindow, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * This method creates a new Ground Stations
     * @summary Create a new Ground Station
     * @param {GroundStation} groundStation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroundStationsApi
     */
    GroundStationsApi.prototype.createGroundStation = function (groundStation, options) {
        var _this = this;
        return exports.GroundStationsApiFp(this.configuration).createGroundStation(groundStation, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * This method deletes a Ground Station with the specified ID
     * @summary Delete a Ground Station by ID
     * @param {string} groundStationId ID of the Ground Station to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroundStationsApi
     */
    GroundStationsApi.prototype.deleteGroundStation = function (groundStationId, options) {
        var _this = this;
        return exports.GroundStationsApiFp(this.configuration).deleteGroundStation(groundStationId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * This method deletes an Unavailability Window from a specified Ground Station
     * @summary Delete an Unavailability Window from a Ground Station
     * @param {string} groundStationId ID of the Ground Station for which delete Unavailability Windows
     * @param {string} unavailabilityWindowId ID of the Unavailability Window to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroundStationsApi
     */
    GroundStationsApi.prototype.deleteUnavailabilityWindow = function (groundStationId, unavailabilityWindowId, options) {
        var _this = this;
        return exports.GroundStationsApiFp(this.configuration).deleteUnavailabilityWindow(groundStationId, unavailabilityWindowId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * This method returns a list of all Ground Stations
     * @summary Get all Ground Stations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroundStationsApi
     */
    GroundStationsApi.prototype.getAllGroundStations = function (options) {
        var _this = this;
        return exports.GroundStationsApiFp(this.configuration).getAllGroundStations(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * This method returns a Ground Station by ID
     * @summary Get a Ground Station by ID
     * @param {string} groundStationId ID of the Ground Station to get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroundStationsApi
     */
    GroundStationsApi.prototype.getGroundStationById = function (groundStationId, options) {
        var _this = this;
        return exports.GroundStationsApiFp(this.configuration).getGroundStationById(groundStationId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * This method returns a list of all Unavailability Windows for a specified Ground Station
     * @summary Get all Unavailability Windowss for a Ground Station
     * @param {string} groundStationId ID of the Ground Station for which to return Unavailability Windows
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroundStationsApi
     */
    GroundStationsApi.prototype.getUnavailabilityWindowsByGroundStationById = function (groundStationId, options) {
        var _this = this;
        return exports.GroundStationsApiFp(this.configuration).getUnavailabilityWindowsByGroundStationById(groundStationId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * This method updates a Ground Station by ID
     * @summary Update a Ground Station
     * @param {string} groundStationId ID of the Ground Station to update
     * @param {GroundStation} groundStation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroundStationsApi
     */
    GroundStationsApi.prototype.updateGroundStation = function (groundStationId, groundStation, options) {
        var _this = this;
        return exports.GroundStationsApiFp(this.configuration).updateGroundStation(groundStationId, groundStation, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return GroundStationsApi;
}(base_1.BaseAPI));
exports.GroundStationsApi = GroundStationsApi;
/**
 * MissionDatabasesApi - axios parameter creator
 * @export
 */
exports.MissionDatabasesApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * This method returns a filtered list of Mission Databases for a satellite. Mission Databases could be filtered by Satellite, Satellite Subsytem, name and type
         * @summary Get Mission Databases
         * @param {string} [satelliteId] Specifies the Satellite for which the Mission Database is applicable
         * @param {string} [satelliteSubsystemId] Specifies the Satellite Subsystem for which the Mission Database is applicable
         * @param {string} [name] Specifies the name of the Mission Database
         * @param {string} [type] Specifies the type of the Mission Database
         * @param {string} [lastEvaluatedItem] Specified the ID of the last item that was returned from the last page
         * @param {number} [pageSizeLimit] Specifies the maximum number of results per page. Defaults to 100
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilteredMissionDatabase: function (satelliteId, satelliteSubsystemId, name, type, lastEvaluatedItem, pageSizeLimit, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/mission-databases";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication MyEnduroSat required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)];
                        case 1:
                            // authentication MyEnduroSat required
                            // oauth required
                            _a.sent();
                            if (satelliteId !== undefined) {
                                localVarQueryParameter['satelliteId'] = satelliteId;
                            }
                            if (satelliteSubsystemId !== undefined) {
                                localVarQueryParameter['satelliteSubsystemId'] = satelliteSubsystemId;
                            }
                            if (name !== undefined) {
                                localVarQueryParameter['name'] = name;
                            }
                            if (type !== undefined) {
                                localVarQueryParameter['type'] = type;
                            }
                            if (lastEvaluatedItem !== undefined) {
                                localVarQueryParameter['lastEvaluatedItem'] = lastEvaluatedItem;
                            }
                            if (pageSizeLimit !== undefined) {
                                localVarQueryParameter['pageSizeLimit'] = pageSizeLimit;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * This method returns Mission Database for a satellite.
         * @summary Get Mission Database By ID
         * @param {string} missionDatabaseId ID of the Mission Database to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMissionDatabaseById: function (missionDatabaseId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'missionDatabaseId' is not null or undefined
                            common_1.assertParamExists('getMissionDatabaseById', 'missionDatabaseId', missionDatabaseId);
                            localVarPath = "/mission-databases/{missionDatabaseId}"
                                .replace("{" + "missionDatabaseId" + "}", encodeURIComponent(String(missionDatabaseId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication MyEnduroSat required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)];
                        case 1:
                            // authentication MyEnduroSat required
                            // oauth required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * This method returns a Mission Database Definition based on the ID of a Mission Database for the current user
         * @summary Get Mission Database Definition by Mission Database ID
         * @param {string} missionDatabaseId ID of the Mission Database for which to get the Definition
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMissionDatabaseDefinition: function (missionDatabaseId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'missionDatabaseId' is not null or undefined
                            common_1.assertParamExists('getMissionDatabaseDefinition', 'missionDatabaseId', missionDatabaseId);
                            localVarPath = "/mission-databases/{missionDatabaseId}/definition"
                                .replace("{" + "missionDatabaseId" + "}", encodeURIComponent(String(missionDatabaseId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication MyEnduroSat required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)];
                        case 1:
                            // authentication MyEnduroSat required
                            // oauth required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * This method returns a URL from which to download Mission Database Parser based on the ID of a Mission Database for the current user
         * @summary Get URL from which to download Mission Database Parser by Mission Database ID
         * @param {string} missionDatabaseId ID of the Mission Database for which to get the URL
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMissionDatabaseParser: function (missionDatabaseId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'missionDatabaseId' is not null or undefined
                            common_1.assertParamExists('getMissionDatabaseParser', 'missionDatabaseId', missionDatabaseId);
                            localVarPath = "/mission-databases/{missionDatabaseId}/parser"
                                .replace("{" + "missionDatabaseId" + "}", encodeURIComponent(String(missionDatabaseId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication MyEnduroSat required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)];
                        case 1:
                            // authentication MyEnduroSat required
                            // oauth required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
/**
 * MissionDatabasesApi - functional programming interface
 * @export
 */
exports.MissionDatabasesApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.MissionDatabasesApiAxiosParamCreator(configuration);
    return {
        /**
         * This method returns a filtered list of Mission Databases for a satellite. Mission Databases could be filtered by Satellite, Satellite Subsytem, name and type
         * @summary Get Mission Databases
         * @param {string} [satelliteId] Specifies the Satellite for which the Mission Database is applicable
         * @param {string} [satelliteSubsystemId] Specifies the Satellite Subsystem for which the Mission Database is applicable
         * @param {string} [name] Specifies the name of the Mission Database
         * @param {string} [type] Specifies the type of the Mission Database
         * @param {string} [lastEvaluatedItem] Specified the ID of the last item that was returned from the last page
         * @param {number} [pageSizeLimit] Specifies the maximum number of results per page. Defaults to 100
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilteredMissionDatabase: function (satelliteId, satelliteSubsystemId, name, type, lastEvaluatedItem, pageSizeLimit, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getFilteredMissionDatabase(satelliteId, satelliteSubsystemId, name, type, lastEvaluatedItem, pageSizeLimit, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * This method returns Mission Database for a satellite.
         * @summary Get Mission Database By ID
         * @param {string} missionDatabaseId ID of the Mission Database to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMissionDatabaseById: function (missionDatabaseId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getMissionDatabaseById(missionDatabaseId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * This method returns a Mission Database Definition based on the ID of a Mission Database for the current user
         * @summary Get Mission Database Definition by Mission Database ID
         * @param {string} missionDatabaseId ID of the Mission Database for which to get the Definition
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMissionDatabaseDefinition: function (missionDatabaseId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getMissionDatabaseDefinition(missionDatabaseId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * This method returns a URL from which to download Mission Database Parser based on the ID of a Mission Database for the current user
         * @summary Get URL from which to download Mission Database Parser by Mission Database ID
         * @param {string} missionDatabaseId ID of the Mission Database for which to get the URL
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMissionDatabaseParser: function (missionDatabaseId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getMissionDatabaseParser(missionDatabaseId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * MissionDatabasesApi - factory interface
 * @export
 */
exports.MissionDatabasesApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.MissionDatabasesApiFp(configuration);
    return {
        /**
         * This method returns a filtered list of Mission Databases for a satellite. Mission Databases could be filtered by Satellite, Satellite Subsytem, name and type
         * @summary Get Mission Databases
         * @param {string} [satelliteId] Specifies the Satellite for which the Mission Database is applicable
         * @param {string} [satelliteSubsystemId] Specifies the Satellite Subsystem for which the Mission Database is applicable
         * @param {string} [name] Specifies the name of the Mission Database
         * @param {string} [type] Specifies the type of the Mission Database
         * @param {string} [lastEvaluatedItem] Specified the ID of the last item that was returned from the last page
         * @param {number} [pageSizeLimit] Specifies the maximum number of results per page. Defaults to 100
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilteredMissionDatabase: function (satelliteId, satelliteSubsystemId, name, type, lastEvaluatedItem, pageSizeLimit, options) {
            return localVarFp.getFilteredMissionDatabase(satelliteId, satelliteSubsystemId, name, type, lastEvaluatedItem, pageSizeLimit, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * This method returns Mission Database for a satellite.
         * @summary Get Mission Database By ID
         * @param {string} missionDatabaseId ID of the Mission Database to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMissionDatabaseById: function (missionDatabaseId, options) {
            return localVarFp.getMissionDatabaseById(missionDatabaseId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * This method returns a Mission Database Definition based on the ID of a Mission Database for the current user
         * @summary Get Mission Database Definition by Mission Database ID
         * @param {string} missionDatabaseId ID of the Mission Database for which to get the Definition
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMissionDatabaseDefinition: function (missionDatabaseId, options) {
            return localVarFp.getMissionDatabaseDefinition(missionDatabaseId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * This method returns a URL from which to download Mission Database Parser based on the ID of a Mission Database for the current user
         * @summary Get URL from which to download Mission Database Parser by Mission Database ID
         * @param {string} missionDatabaseId ID of the Mission Database for which to get the URL
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMissionDatabaseParser: function (missionDatabaseId, options) {
            return localVarFp.getMissionDatabaseParser(missionDatabaseId, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * MissionDatabasesApi - object-oriented interface
 * @export
 * @class MissionDatabasesApi
 * @extends {BaseAPI}
 */
var MissionDatabasesApi = /** @class */ (function (_super) {
    __extends(MissionDatabasesApi, _super);
    function MissionDatabasesApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * This method returns a filtered list of Mission Databases for a satellite. Mission Databases could be filtered by Satellite, Satellite Subsytem, name and type
     * @summary Get Mission Databases
     * @param {string} [satelliteId] Specifies the Satellite for which the Mission Database is applicable
     * @param {string} [satelliteSubsystemId] Specifies the Satellite Subsystem for which the Mission Database is applicable
     * @param {string} [name] Specifies the name of the Mission Database
     * @param {string} [type] Specifies the type of the Mission Database
     * @param {string} [lastEvaluatedItem] Specified the ID of the last item that was returned from the last page
     * @param {number} [pageSizeLimit] Specifies the maximum number of results per page. Defaults to 100
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionDatabasesApi
     */
    MissionDatabasesApi.prototype.getFilteredMissionDatabase = function (satelliteId, satelliteSubsystemId, name, type, lastEvaluatedItem, pageSizeLimit, options) {
        var _this = this;
        return exports.MissionDatabasesApiFp(this.configuration).getFilteredMissionDatabase(satelliteId, satelliteSubsystemId, name, type, lastEvaluatedItem, pageSizeLimit, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * This method returns Mission Database for a satellite.
     * @summary Get Mission Database By ID
     * @param {string} missionDatabaseId ID of the Mission Database to get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionDatabasesApi
     */
    MissionDatabasesApi.prototype.getMissionDatabaseById = function (missionDatabaseId, options) {
        var _this = this;
        return exports.MissionDatabasesApiFp(this.configuration).getMissionDatabaseById(missionDatabaseId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * This method returns a Mission Database Definition based on the ID of a Mission Database for the current user
     * @summary Get Mission Database Definition by Mission Database ID
     * @param {string} missionDatabaseId ID of the Mission Database for which to get the Definition
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionDatabasesApi
     */
    MissionDatabasesApi.prototype.getMissionDatabaseDefinition = function (missionDatabaseId, options) {
        var _this = this;
        return exports.MissionDatabasesApiFp(this.configuration).getMissionDatabaseDefinition(missionDatabaseId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * This method returns a URL from which to download Mission Database Parser based on the ID of a Mission Database for the current user
     * @summary Get URL from which to download Mission Database Parser by Mission Database ID
     * @param {string} missionDatabaseId ID of the Mission Database for which to get the URL
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionDatabasesApi
     */
    MissionDatabasesApi.prototype.getMissionDatabaseParser = function (missionDatabaseId, options) {
        var _this = this;
        return exports.MissionDatabasesApiFp(this.configuration).getMissionDatabaseParser(missionDatabaseId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return MissionDatabasesApi;
}(base_1.BaseAPI));
exports.MissionDatabasesApi = MissionDatabasesApi;
/**
 * PayloadFilesApi - axios parameter creator
 * @export
 */
exports.PayloadFilesApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * This method finishes a multipart upload
         * @summary Finish multipart upload
         * @param {string} multipartUploadId
         * @param {Array<string>} eTags
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finishMultipartUpload: function (multipartUploadId, eTags, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'multipartUploadId' is not null or undefined
                            common_1.assertParamExists('finishMultipartUpload', 'multipartUploadId', multipartUploadId);
                            // verify required parameter 'eTags' is not null or undefined
                            common_1.assertParamExists('finishMultipartUpload', 'eTags', eTags);
                            localVarPath = "/payload-files/finish";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication MyEnduroSat required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)];
                        case 1:
                            // authentication MyEnduroSat required
                            // oauth required
                            _a.sent();
                            if (multipartUploadId !== undefined) {
                                localVarQueryParameter['MultipartUploadId'] = multipartUploadId;
                            }
                            if (eTags) {
                                localVarQueryParameter['eTags'] = eTags;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * This method returns a URL from which to download a file
         * @summary Get a URL from which to download a file
         * @param {string} resourceType Type of the resource for which to get a file. Possible values are \&#39;telemetry\&#39;, \&#39;telecommand\&#39; and \&#39;satellite-pass\&#39;.
         * @param {string} resourceId Id of the resource for which to get a file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDownloadURL: function (resourceType, resourceId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'resourceType' is not null or undefined
                            common_1.assertParamExists('getDownloadURL', 'resourceType', resourceType);
                            // verify required parameter 'resourceId' is not null or undefined
                            common_1.assertParamExists('getDownloadURL', 'resourceId', resourceId);
                            localVarPath = "/payload-files/{resourceType}/{resourceId}"
                                .replace("{" + "resourceType" + "}", encodeURIComponent(String(resourceType)))
                                .replace("{" + "resourceId" + "}", encodeURIComponent(String(resourceId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication MyEnduroSat required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)];
                        case 1:
                            // authentication MyEnduroSat required
                            // oauth required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * This method begins a multipart upload
         * @summary Initiate Multipart Upload
         * @param {string} resourceType Type of the resource for which to init a file upload. Possible values are \&#39;telemetry\&#39;, \&#39;telecommand\&#39; and \&#39;satellite-pass\&#39;.
         * @param {string} resourceId Id of the resource for which to init a file upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiateMultipartUpload: function (resourceType, resourceId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'resourceType' is not null or undefined
                            common_1.assertParamExists('initiateMultipartUpload', 'resourceType', resourceType);
                            // verify required parameter 'resourceId' is not null or undefined
                            common_1.assertParamExists('initiateMultipartUpload', 'resourceId', resourceId);
                            localVarPath = "/payload-files/multipart/{resourceType}/{resourceId}"
                                .replace("{" + "resourceType" + "}", encodeURIComponent(String(resourceType)))
                                .replace("{" + "resourceId" + "}", encodeURIComponent(String(resourceId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication MyEnduroSat required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)];
                        case 1:
                            // authentication MyEnduroSat required
                            // oauth required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * This method attaches a payload file to a resouce
         * @summary Attach payload file to resouce
         * @param {string} resourceType Type of the resource for which to upload a file. Possible values are \&#39;telemetry\&#39;, \&#39;telecommand\&#39; and \&#39;satellite-pass\&#39;.
         * @param {string} resourceId Id of the resource for which to upload a file
         * @param {any} payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile: function (resourceType, resourceId, payload, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'resourceType' is not null or undefined
                            common_1.assertParamExists('uploadFile', 'resourceType', resourceType);
                            // verify required parameter 'resourceId' is not null or undefined
                            common_1.assertParamExists('uploadFile', 'resourceId', resourceId);
                            // verify required parameter 'payload' is not null or undefined
                            common_1.assertParamExists('uploadFile', 'payload', payload);
                            localVarPath = "/payload-files/{resourceType}/{resourceId}"
                                .replace("{" + "resourceType" + "}", encodeURIComponent(String(resourceType)))
                                .replace("{" + "resourceId" + "}", encodeURIComponent(String(resourceId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
                            // authentication MyEnduroSat required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)];
                        case 1:
                            // authentication MyEnduroSat required
                            // oauth required
                            _a.sent();
                            if (payload !== undefined) {
                                localVarFormParams.append('payload', payload);
                            }
                            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = localVarFormParams;
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * This method uploads a part of a multipart file upload
         * @summary Upload Part
         * @param {string} multipartUploadId
         * @param {number} partNumber
         * @param {any} payload
         * @param {boolean} [lastPart]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPart: function (multipartUploadId, partNumber, payload, lastPart, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'multipartUploadId' is not null or undefined
                            common_1.assertParamExists('uploadPart', 'multipartUploadId', multipartUploadId);
                            // verify required parameter 'partNumber' is not null or undefined
                            common_1.assertParamExists('uploadPart', 'partNumber', partNumber);
                            // verify required parameter 'payload' is not null or undefined
                            common_1.assertParamExists('uploadPart', 'payload', payload);
                            localVarPath = "/payload-files/upload-part";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
                            // authentication MyEnduroSat required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)];
                        case 1:
                            // authentication MyEnduroSat required
                            // oauth required
                            _a.sent();
                            if (multipartUploadId !== undefined) {
                                localVarQueryParameter['MultipartUploadId'] = multipartUploadId;
                            }
                            if (partNumber !== undefined) {
                                localVarQueryParameter['partNumber'] = partNumber;
                            }
                            if (lastPart !== undefined) {
                                localVarQueryParameter['lastPart'] = lastPart;
                            }
                            if (payload !== undefined) {
                                localVarFormParams.append('payload', payload);
                            }
                            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = localVarFormParams;
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
/**
 * PayloadFilesApi - functional programming interface
 * @export
 */
exports.PayloadFilesApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.PayloadFilesApiAxiosParamCreator(configuration);
    return {
        /**
         * This method finishes a multipart upload
         * @summary Finish multipart upload
         * @param {string} multipartUploadId
         * @param {Array<string>} eTags
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finishMultipartUpload: function (multipartUploadId, eTags, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.finishMultipartUpload(multipartUploadId, eTags, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * This method returns a URL from which to download a file
         * @summary Get a URL from which to download a file
         * @param {string} resourceType Type of the resource for which to get a file. Possible values are \&#39;telemetry\&#39;, \&#39;telecommand\&#39; and \&#39;satellite-pass\&#39;.
         * @param {string} resourceId Id of the resource for which to get a file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDownloadURL: function (resourceType, resourceId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getDownloadURL(resourceType, resourceId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * This method begins a multipart upload
         * @summary Initiate Multipart Upload
         * @param {string} resourceType Type of the resource for which to init a file upload. Possible values are \&#39;telemetry\&#39;, \&#39;telecommand\&#39; and \&#39;satellite-pass\&#39;.
         * @param {string} resourceId Id of the resource for which to init a file upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiateMultipartUpload: function (resourceType, resourceId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.initiateMultipartUpload(resourceType, resourceId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * This method attaches a payload file to a resouce
         * @summary Attach payload file to resouce
         * @param {string} resourceType Type of the resource for which to upload a file. Possible values are \&#39;telemetry\&#39;, \&#39;telecommand\&#39; and \&#39;satellite-pass\&#39;.
         * @param {string} resourceId Id of the resource for which to upload a file
         * @param {any} payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile: function (resourceType, resourceId, payload, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.uploadFile(resourceType, resourceId, payload, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * This method uploads a part of a multipart file upload
         * @summary Upload Part
         * @param {string} multipartUploadId
         * @param {number} partNumber
         * @param {any} payload
         * @param {boolean} [lastPart]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPart: function (multipartUploadId, partNumber, payload, lastPart, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.uploadPart(multipartUploadId, partNumber, payload, lastPart, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * PayloadFilesApi - factory interface
 * @export
 */
exports.PayloadFilesApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.PayloadFilesApiFp(configuration);
    return {
        /**
         * This method finishes a multipart upload
         * @summary Finish multipart upload
         * @param {string} multipartUploadId
         * @param {Array<string>} eTags
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finishMultipartUpload: function (multipartUploadId, eTags, options) {
            return localVarFp.finishMultipartUpload(multipartUploadId, eTags, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * This method returns a URL from which to download a file
         * @summary Get a URL from which to download a file
         * @param {string} resourceType Type of the resource for which to get a file. Possible values are \&#39;telemetry\&#39;, \&#39;telecommand\&#39; and \&#39;satellite-pass\&#39;.
         * @param {string} resourceId Id of the resource for which to get a file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDownloadURL: function (resourceType, resourceId, options) {
            return localVarFp.getDownloadURL(resourceType, resourceId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * This method begins a multipart upload
         * @summary Initiate Multipart Upload
         * @param {string} resourceType Type of the resource for which to init a file upload. Possible values are \&#39;telemetry\&#39;, \&#39;telecommand\&#39; and \&#39;satellite-pass\&#39;.
         * @param {string} resourceId Id of the resource for which to init a file upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiateMultipartUpload: function (resourceType, resourceId, options) {
            return localVarFp.initiateMultipartUpload(resourceType, resourceId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * This method attaches a payload file to a resouce
         * @summary Attach payload file to resouce
         * @param {string} resourceType Type of the resource for which to upload a file. Possible values are \&#39;telemetry\&#39;, \&#39;telecommand\&#39; and \&#39;satellite-pass\&#39;.
         * @param {string} resourceId Id of the resource for which to upload a file
         * @param {any} payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile: function (resourceType, resourceId, payload, options) {
            return localVarFp.uploadFile(resourceType, resourceId, payload, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * This method uploads a part of a multipart file upload
         * @summary Upload Part
         * @param {string} multipartUploadId
         * @param {number} partNumber
         * @param {any} payload
         * @param {boolean} [lastPart]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPart: function (multipartUploadId, partNumber, payload, lastPart, options) {
            return localVarFp.uploadPart(multipartUploadId, partNumber, payload, lastPart, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * PayloadFilesApi - object-oriented interface
 * @export
 * @class PayloadFilesApi
 * @extends {BaseAPI}
 */
var PayloadFilesApi = /** @class */ (function (_super) {
    __extends(PayloadFilesApi, _super);
    function PayloadFilesApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * This method finishes a multipart upload
     * @summary Finish multipart upload
     * @param {string} multipartUploadId
     * @param {Array<string>} eTags
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayloadFilesApi
     */
    PayloadFilesApi.prototype.finishMultipartUpload = function (multipartUploadId, eTags, options) {
        var _this = this;
        return exports.PayloadFilesApiFp(this.configuration).finishMultipartUpload(multipartUploadId, eTags, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * This method returns a URL from which to download a file
     * @summary Get a URL from which to download a file
     * @param {string} resourceType Type of the resource for which to get a file. Possible values are \&#39;telemetry\&#39;, \&#39;telecommand\&#39; and \&#39;satellite-pass\&#39;.
     * @param {string} resourceId Id of the resource for which to get a file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayloadFilesApi
     */
    PayloadFilesApi.prototype.getDownloadURL = function (resourceType, resourceId, options) {
        var _this = this;
        return exports.PayloadFilesApiFp(this.configuration).getDownloadURL(resourceType, resourceId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * This method begins a multipart upload
     * @summary Initiate Multipart Upload
     * @param {string} resourceType Type of the resource for which to init a file upload. Possible values are \&#39;telemetry\&#39;, \&#39;telecommand\&#39; and \&#39;satellite-pass\&#39;.
     * @param {string} resourceId Id of the resource for which to init a file upload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayloadFilesApi
     */
    PayloadFilesApi.prototype.initiateMultipartUpload = function (resourceType, resourceId, options) {
        var _this = this;
        return exports.PayloadFilesApiFp(this.configuration).initiateMultipartUpload(resourceType, resourceId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * This method attaches a payload file to a resouce
     * @summary Attach payload file to resouce
     * @param {string} resourceType Type of the resource for which to upload a file. Possible values are \&#39;telemetry\&#39;, \&#39;telecommand\&#39; and \&#39;satellite-pass\&#39;.
     * @param {string} resourceId Id of the resource for which to upload a file
     * @param {any} payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayloadFilesApi
     */
    PayloadFilesApi.prototype.uploadFile = function (resourceType, resourceId, payload, options) {
        var _this = this;
        return exports.PayloadFilesApiFp(this.configuration).uploadFile(resourceType, resourceId, payload, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * This method uploads a part of a multipart file upload
     * @summary Upload Part
     * @param {string} multipartUploadId
     * @param {number} partNumber
     * @param {any} payload
     * @param {boolean} [lastPart]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayloadFilesApi
     */
    PayloadFilesApi.prototype.uploadPart = function (multipartUploadId, partNumber, payload, lastPart, options) {
        var _this = this;
        return exports.PayloadFilesApiFp(this.configuration).uploadPart(multipartUploadId, partNumber, payload, lastPart, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return PayloadFilesApi;
}(base_1.BaseAPI));
exports.PayloadFilesApi = PayloadFilesApi;
/**
 * PayloadSlotsApi - axios parameter creator
 * @export
 */
exports.PayloadSlotsApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * This method creates a new Payload Slot for the current user.
         * @summary Create a Payload Slot
         * @param {PayloadSlot} payloadSlot
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPayloadSlot: function (payloadSlot, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'payloadSlot' is not null or undefined
                            common_1.assertParamExists('createPayloadSlot', 'payloadSlot', payloadSlot);
                            localVarPath = "/payload-slots";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication MyEnduroSat required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)];
                        case 1:
                            // authentication MyEnduroSat required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(payloadSlot, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * This method returns a Payload Slot by ID
         * @summary Get a Payload Slot by ID
         * @param {string} payloadSlotId ID of the Payload Slot to get.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPayloadSlotById: function (payloadSlotId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'payloadSlotId' is not null or undefined
                            common_1.assertParamExists('getPayloadSlotById', 'payloadSlotId', payloadSlotId);
                            localVarPath = "/payload-slots/{payloadSlotId}"
                                .replace("{" + "payloadSlotId" + "}", encodeURIComponent(String(payloadSlotId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication MyEnduroSat required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)];
                        case 1:
                            // authentication MyEnduroSat required
                            // oauth required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * This method returns a list of available Payload Slots for a payload satellite subsytem
         * @summary Get a list of available Payload Slots
         * @param {string} satelliteSubsystem ID of the satellite subsystem (payload) to get slots for.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPayloadSlots: function (satelliteSubsystem, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'satelliteSubsystem' is not null or undefined
                            common_1.assertParamExists('getPayloadSlots', 'satelliteSubsystem', satelliteSubsystem);
                            localVarPath = "/payload-slots";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication MyEnduroSat required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)];
                        case 1:
                            // authentication MyEnduroSat required
                            // oauth required
                            _a.sent();
                            if (satelliteSubsystem !== undefined) {
                                localVarQueryParameter['satelliteSubsystem'] = satelliteSubsystem;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
/**
 * PayloadSlotsApi - functional programming interface
 * @export
 */
exports.PayloadSlotsApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.PayloadSlotsApiAxiosParamCreator(configuration);
    return {
        /**
         * This method creates a new Payload Slot for the current user.
         * @summary Create a Payload Slot
         * @param {PayloadSlot} payloadSlot
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPayloadSlot: function (payloadSlot, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createPayloadSlot(payloadSlot, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * This method returns a Payload Slot by ID
         * @summary Get a Payload Slot by ID
         * @param {string} payloadSlotId ID of the Payload Slot to get.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPayloadSlotById: function (payloadSlotId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getPayloadSlotById(payloadSlotId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * This method returns a list of available Payload Slots for a payload satellite subsytem
         * @summary Get a list of available Payload Slots
         * @param {string} satelliteSubsystem ID of the satellite subsystem (payload) to get slots for.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPayloadSlots: function (satelliteSubsystem, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getPayloadSlots(satelliteSubsystem, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * PayloadSlotsApi - factory interface
 * @export
 */
exports.PayloadSlotsApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.PayloadSlotsApiFp(configuration);
    return {
        /**
         * This method creates a new Payload Slot for the current user.
         * @summary Create a Payload Slot
         * @param {PayloadSlot} payloadSlot
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPayloadSlot: function (payloadSlot, options) {
            return localVarFp.createPayloadSlot(payloadSlot, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * This method returns a Payload Slot by ID
         * @summary Get a Payload Slot by ID
         * @param {string} payloadSlotId ID of the Payload Slot to get.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPayloadSlotById: function (payloadSlotId, options) {
            return localVarFp.getPayloadSlotById(payloadSlotId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * This method returns a list of available Payload Slots for a payload satellite subsytem
         * @summary Get a list of available Payload Slots
         * @param {string} satelliteSubsystem ID of the satellite subsystem (payload) to get slots for.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPayloadSlots: function (satelliteSubsystem, options) {
            return localVarFp.getPayloadSlots(satelliteSubsystem, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * PayloadSlotsApi - object-oriented interface
 * @export
 * @class PayloadSlotsApi
 * @extends {BaseAPI}
 */
var PayloadSlotsApi = /** @class */ (function (_super) {
    __extends(PayloadSlotsApi, _super);
    function PayloadSlotsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * This method creates a new Payload Slot for the current user.
     * @summary Create a Payload Slot
     * @param {PayloadSlot} payloadSlot
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayloadSlotsApi
     */
    PayloadSlotsApi.prototype.createPayloadSlot = function (payloadSlot, options) {
        var _this = this;
        return exports.PayloadSlotsApiFp(this.configuration).createPayloadSlot(payloadSlot, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * This method returns a Payload Slot by ID
     * @summary Get a Payload Slot by ID
     * @param {string} payloadSlotId ID of the Payload Slot to get.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayloadSlotsApi
     */
    PayloadSlotsApi.prototype.getPayloadSlotById = function (payloadSlotId, options) {
        var _this = this;
        return exports.PayloadSlotsApiFp(this.configuration).getPayloadSlotById(payloadSlotId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * This method returns a list of available Payload Slots for a payload satellite subsytem
     * @summary Get a list of available Payload Slots
     * @param {string} satelliteSubsystem ID of the satellite subsystem (payload) to get slots for.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayloadSlotsApi
     */
    PayloadSlotsApi.prototype.getPayloadSlots = function (satelliteSubsystem, options) {
        var _this = this;
        return exports.PayloadSlotsApiFp(this.configuration).getPayloadSlots(satelliteSubsystem, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return PayloadSlotsApi;
}(base_1.BaseAPI));
exports.PayloadSlotsApi = PayloadSlotsApi;
/**
 * SatellitePassPredictionsApi - axios parameter creator
 * @export
 */
exports.SatellitePassPredictionsApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * This method returns a list of pass predictions that could be filtered by satellite, ground station and time window. These predictions can then be used to schedule a pass. Each prediction is valid for 20 minutes. After that the prediction API need to be called again.
         * @summary Get a list of pass predictions
         * @param {string} satellite ID of the satellite for which to filter.
         * @param {string} [groundStation] ID of the ground station for which to filter. If not present results for all ground stations are returned.
         * @param {number} [hoursAhead] Specified the time window in hours from now for which to predict passes. Defaults to 10.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPassPredictions: function (satellite, groundStation, hoursAhead, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'satellite' is not null or undefined
                            common_1.assertParamExists('getPassPredictions', 'satellite', satellite);
                            localVarPath = "/pass-predictions";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication MyEnduroSat required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)];
                        case 1:
                            // authentication MyEnduroSat required
                            // oauth required
                            _a.sent();
                            if (satellite !== undefined) {
                                localVarQueryParameter['satellite'] = satellite;
                            }
                            if (groundStation !== undefined) {
                                localVarQueryParameter['ground-station'] = groundStation;
                            }
                            if (hoursAhead !== undefined) {
                                localVarQueryParameter['hours-ahead'] = hoursAhead;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
/**
 * SatellitePassPredictionsApi - functional programming interface
 * @export
 */
exports.SatellitePassPredictionsApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.SatellitePassPredictionsApiAxiosParamCreator(configuration);
    return {
        /**
         * This method returns a list of pass predictions that could be filtered by satellite, ground station and time window. These predictions can then be used to schedule a pass. Each prediction is valid for 20 minutes. After that the prediction API need to be called again.
         * @summary Get a list of pass predictions
         * @param {string} satellite ID of the satellite for which to filter.
         * @param {string} [groundStation] ID of the ground station for which to filter. If not present results for all ground stations are returned.
         * @param {number} [hoursAhead] Specified the time window in hours from now for which to predict passes. Defaults to 10.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPassPredictions: function (satellite, groundStation, hoursAhead, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getPassPredictions(satellite, groundStation, hoursAhead, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * SatellitePassPredictionsApi - factory interface
 * @export
 */
exports.SatellitePassPredictionsApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.SatellitePassPredictionsApiFp(configuration);
    return {
        /**
         * This method returns a list of pass predictions that could be filtered by satellite, ground station and time window. These predictions can then be used to schedule a pass. Each prediction is valid for 20 minutes. After that the prediction API need to be called again.
         * @summary Get a list of pass predictions
         * @param {string} satellite ID of the satellite for which to filter.
         * @param {string} [groundStation] ID of the ground station for which to filter. If not present results for all ground stations are returned.
         * @param {number} [hoursAhead] Specified the time window in hours from now for which to predict passes. Defaults to 10.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPassPredictions: function (satellite, groundStation, hoursAhead, options) {
            return localVarFp.getPassPredictions(satellite, groundStation, hoursAhead, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * SatellitePassPredictionsApi - object-oriented interface
 * @export
 * @class SatellitePassPredictionsApi
 * @extends {BaseAPI}
 */
var SatellitePassPredictionsApi = /** @class */ (function (_super) {
    __extends(SatellitePassPredictionsApi, _super);
    function SatellitePassPredictionsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * This method returns a list of pass predictions that could be filtered by satellite, ground station and time window. These predictions can then be used to schedule a pass. Each prediction is valid for 20 minutes. After that the prediction API need to be called again.
     * @summary Get a list of pass predictions
     * @param {string} satellite ID of the satellite for which to filter.
     * @param {string} [groundStation] ID of the ground station for which to filter. If not present results for all ground stations are returned.
     * @param {number} [hoursAhead] Specified the time window in hours from now for which to predict passes. Defaults to 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SatellitePassPredictionsApi
     */
    SatellitePassPredictionsApi.prototype.getPassPredictions = function (satellite, groundStation, hoursAhead, options) {
        var _this = this;
        return exports.SatellitePassPredictionsApiFp(this.configuration).getPassPredictions(satellite, groundStation, hoursAhead, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return SatellitePassPredictionsApi;
}(base_1.BaseAPI));
exports.SatellitePassPredictionsApi = SatellitePassPredictionsApi;
/**
 * SatellitePassesApi - axios parameter creator
 * @export
 */
exports.SatellitePassesApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * This method cancels a satellite pass by ID for the current user
         * @summary Cancel a Satellite Pass
         * @param {string} satellitePassId ID of the Satellite Pass to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelSatellitePass: function (satellitePassId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'satellitePassId' is not null or undefined
                            common_1.assertParamExists('cancelSatellitePass', 'satellitePassId', satellitePassId);
                            localVarPath = "/satellite-passes/{satellitePassId}"
                                .replace("{" + "satellitePassId" + "}", encodeURIComponent(String(satellitePassId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication MyEnduroSat required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)];
                        case 1:
                            // authentication MyEnduroSat required
                            // oauth required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * This method is used for booking passes for the current user. It requires a Pass Prediction obtained from the Pass Prediction API to be provided in the body of the request. Pass predictions are valid for 20 minutes.If the prediction has expired or is ivalid, a 400 Bad Request response will be returned.
         * @summary Book a Satellite Pass
         * @param {PassPrediction} passPrediction
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSatellitePass: function (passPrediction, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'passPrediction' is not null or undefined
                            common_1.assertParamExists('createSatellitePass', 'passPrediction', passPrediction);
                            localVarPath = "/satellite-passes";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication MyEnduroSat required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)];
                        case 1:
                            // authentication MyEnduroSat required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(passPrediction, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * This method returns a filtered list of Satellite Passes. Satellite Passes could be filtered by Satellite, Ground Station and Status
         * @summary Get Filtered Satellite Passes
         * @param {string} [satelliteId] Specifies the satellite for which to get Satellite Passes
         * @param {Array<string>} [groundStation] Specifies the Ground Station(s) of the Satellite Passes to get.
         * @param {'PREDICTION' | 'SCHEDULED' | 'IN_PROGRESS' | 'FINISHED_SUCCESSFULLY' | 'FINISHED_WITH_ERRORS' | 'CANCELED' | 'MISSED'} [status] Specifies the Status (or part of the Status) of the Satellite Passes to get
         * @param {number} [fromTime] Filter to only return entries that were received after the specified time (inclusive)
         * @param {number} [toTime] Filter to only return entries that were received before the specified time (exclusive)
         * @param {string} [lastEvaluatedItem] Specified the ID of the last item that was returned from the last page
         * @param {number} [pageSizeLimit] Specifies the maximum number of results per page. Defaults to 100
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilteredSatellitePasses: function (satelliteId, groundStation, status, fromTime, toTime, lastEvaluatedItem, pageSizeLimit, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/satellite-passes";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication MyEnduroSat required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)];
                        case 1:
                            // authentication MyEnduroSat required
                            // oauth required
                            _a.sent();
                            if (satelliteId !== undefined) {
                                localVarQueryParameter['satelliteId'] = satelliteId;
                            }
                            if (groundStation) {
                                localVarQueryParameter['groundStation'] = groundStation;
                            }
                            if (status !== undefined) {
                                localVarQueryParameter['status'] = status;
                            }
                            if (fromTime !== undefined) {
                                localVarQueryParameter['fromTime'] = fromTime;
                            }
                            if (toTime !== undefined) {
                                localVarQueryParameter['toTime'] = toTime;
                            }
                            if (lastEvaluatedItem !== undefined) {
                                localVarQueryParameter['lastEvaluatedItem'] = lastEvaluatedItem;
                            }
                            if (pageSizeLimit !== undefined) {
                                localVarQueryParameter['pageSizeLimit'] = pageSizeLimit;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * This method returns a pass by ID for the current user
         * @summary Get a Satellite Pass by ID
         * @param {string} satellitePassId ID of the Satellite Pass to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSatellitePassById: function (satellitePassId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'satellitePassId' is not null or undefined
                            common_1.assertParamExists('getSatellitePassById', 'satellitePassId', satellitePassId);
                            localVarPath = "/satellite-passes/{satellitePassId}"
                                .replace("{" + "satellitePassId" + "}", encodeURIComponent(String(satellitePassId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication MyEnduroSat required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)];
                        case 1:
                            // authentication MyEnduroSat required
                            // oauth required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * This method records a lof of the satellite pass
         * @summary Record a Satellite Pass Log
         * @param {string} satellitePassId ID of the Satellite Pass to update
         * @param {any} payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recordSatellitePassLog: function (satellitePassId, payload, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'satellitePassId' is not null or undefined
                            common_1.assertParamExists('recordSatellitePassLog', 'satellitePassId', satellitePassId);
                            // verify required parameter 'payload' is not null or undefined
                            common_1.assertParamExists('recordSatellitePassLog', 'payload', payload);
                            localVarPath = "/satellite-passes/{satellitePassId}/log"
                                .replace("{" + "satellitePassId" + "}", encodeURIComponent(String(satellitePassId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
                            // authentication MyEnduroSat required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)];
                        case 1:
                            // authentication MyEnduroSat required
                            // oauth required
                            _a.sent();
                            if (payload !== undefined) {
                                localVarFormParams.append('payload', payload);
                            }
                            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = localVarFormParams;
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * This method updates a satellite pass by ID for the current user
         * @summary Update a Satellite Pass
         * @param {string} satellitePassId ID of the Satellite Pass to update
         * @param {SatellitePass} satellitePass
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSatellitePassById: function (satellitePassId, satellitePass, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'satellitePassId' is not null or undefined
                            common_1.assertParamExists('updateSatellitePassById', 'satellitePassId', satellitePassId);
                            // verify required parameter 'satellitePass' is not null or undefined
                            common_1.assertParamExists('updateSatellitePassById', 'satellitePass', satellitePass);
                            localVarPath = "/satellite-passes/{satellitePassId}"
                                .replace("{" + "satellitePassId" + "}", encodeURIComponent(String(satellitePassId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication MyEnduroSat required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)];
                        case 1:
                            // authentication MyEnduroSat required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(satellitePass, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
/**
 * SatellitePassesApi - functional programming interface
 * @export
 */
exports.SatellitePassesApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.SatellitePassesApiAxiosParamCreator(configuration);
    return {
        /**
         * This method cancels a satellite pass by ID for the current user
         * @summary Cancel a Satellite Pass
         * @param {string} satellitePassId ID of the Satellite Pass to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelSatellitePass: function (satellitePassId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.cancelSatellitePass(satellitePassId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * This method is used for booking passes for the current user. It requires a Pass Prediction obtained from the Pass Prediction API to be provided in the body of the request. Pass predictions are valid for 20 minutes.If the prediction has expired or is ivalid, a 400 Bad Request response will be returned.
         * @summary Book a Satellite Pass
         * @param {PassPrediction} passPrediction
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSatellitePass: function (passPrediction, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createSatellitePass(passPrediction, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * This method returns a filtered list of Satellite Passes. Satellite Passes could be filtered by Satellite, Ground Station and Status
         * @summary Get Filtered Satellite Passes
         * @param {string} [satelliteId] Specifies the satellite for which to get Satellite Passes
         * @param {Array<string>} [groundStation] Specifies the Ground Station(s) of the Satellite Passes to get.
         * @param {'PREDICTION' | 'SCHEDULED' | 'IN_PROGRESS' | 'FINISHED_SUCCESSFULLY' | 'FINISHED_WITH_ERRORS' | 'CANCELED' | 'MISSED'} [status] Specifies the Status (or part of the Status) of the Satellite Passes to get
         * @param {number} [fromTime] Filter to only return entries that were received after the specified time (inclusive)
         * @param {number} [toTime] Filter to only return entries that were received before the specified time (exclusive)
         * @param {string} [lastEvaluatedItem] Specified the ID of the last item that was returned from the last page
         * @param {number} [pageSizeLimit] Specifies the maximum number of results per page. Defaults to 100
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilteredSatellitePasses: function (satelliteId, groundStation, status, fromTime, toTime, lastEvaluatedItem, pageSizeLimit, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getFilteredSatellitePasses(satelliteId, groundStation, status, fromTime, toTime, lastEvaluatedItem, pageSizeLimit, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * This method returns a pass by ID for the current user
         * @summary Get a Satellite Pass by ID
         * @param {string} satellitePassId ID of the Satellite Pass to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSatellitePassById: function (satellitePassId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getSatellitePassById(satellitePassId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * This method records a lof of the satellite pass
         * @summary Record a Satellite Pass Log
         * @param {string} satellitePassId ID of the Satellite Pass to update
         * @param {any} payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recordSatellitePassLog: function (satellitePassId, payload, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.recordSatellitePassLog(satellitePassId, payload, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * This method updates a satellite pass by ID for the current user
         * @summary Update a Satellite Pass
         * @param {string} satellitePassId ID of the Satellite Pass to update
         * @param {SatellitePass} satellitePass
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSatellitePassById: function (satellitePassId, satellitePass, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateSatellitePassById(satellitePassId, satellitePass, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * SatellitePassesApi - factory interface
 * @export
 */
exports.SatellitePassesApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.SatellitePassesApiFp(configuration);
    return {
        /**
         * This method cancels a satellite pass by ID for the current user
         * @summary Cancel a Satellite Pass
         * @param {string} satellitePassId ID of the Satellite Pass to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelSatellitePass: function (satellitePassId, options) {
            return localVarFp.cancelSatellitePass(satellitePassId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * This method is used for booking passes for the current user. It requires a Pass Prediction obtained from the Pass Prediction API to be provided in the body of the request. Pass predictions are valid for 20 minutes.If the prediction has expired or is ivalid, a 400 Bad Request response will be returned.
         * @summary Book a Satellite Pass
         * @param {PassPrediction} passPrediction
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSatellitePass: function (passPrediction, options) {
            return localVarFp.createSatellitePass(passPrediction, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * This method returns a filtered list of Satellite Passes. Satellite Passes could be filtered by Satellite, Ground Station and Status
         * @summary Get Filtered Satellite Passes
         * @param {string} [satelliteId] Specifies the satellite for which to get Satellite Passes
         * @param {Array<string>} [groundStation] Specifies the Ground Station(s) of the Satellite Passes to get.
         * @param {'PREDICTION' | 'SCHEDULED' | 'IN_PROGRESS' | 'FINISHED_SUCCESSFULLY' | 'FINISHED_WITH_ERRORS' | 'CANCELED' | 'MISSED'} [status] Specifies the Status (or part of the Status) of the Satellite Passes to get
         * @param {number} [fromTime] Filter to only return entries that were received after the specified time (inclusive)
         * @param {number} [toTime] Filter to only return entries that were received before the specified time (exclusive)
         * @param {string} [lastEvaluatedItem] Specified the ID of the last item that was returned from the last page
         * @param {number} [pageSizeLimit] Specifies the maximum number of results per page. Defaults to 100
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilteredSatellitePasses: function (satelliteId, groundStation, status, fromTime, toTime, lastEvaluatedItem, pageSizeLimit, options) {
            return localVarFp.getFilteredSatellitePasses(satelliteId, groundStation, status, fromTime, toTime, lastEvaluatedItem, pageSizeLimit, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * This method returns a pass by ID for the current user
         * @summary Get a Satellite Pass by ID
         * @param {string} satellitePassId ID of the Satellite Pass to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSatellitePassById: function (satellitePassId, options) {
            return localVarFp.getSatellitePassById(satellitePassId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * This method records a lof of the satellite pass
         * @summary Record a Satellite Pass Log
         * @param {string} satellitePassId ID of the Satellite Pass to update
         * @param {any} payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recordSatellitePassLog: function (satellitePassId, payload, options) {
            return localVarFp.recordSatellitePassLog(satellitePassId, payload, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * This method updates a satellite pass by ID for the current user
         * @summary Update a Satellite Pass
         * @param {string} satellitePassId ID of the Satellite Pass to update
         * @param {SatellitePass} satellitePass
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSatellitePassById: function (satellitePassId, satellitePass, options) {
            return localVarFp.updateSatellitePassById(satellitePassId, satellitePass, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * SatellitePassesApi - object-oriented interface
 * @export
 * @class SatellitePassesApi
 * @extends {BaseAPI}
 */
var SatellitePassesApi = /** @class */ (function (_super) {
    __extends(SatellitePassesApi, _super);
    function SatellitePassesApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * This method cancels a satellite pass by ID for the current user
     * @summary Cancel a Satellite Pass
     * @param {string} satellitePassId ID of the Satellite Pass to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SatellitePassesApi
     */
    SatellitePassesApi.prototype.cancelSatellitePass = function (satellitePassId, options) {
        var _this = this;
        return exports.SatellitePassesApiFp(this.configuration).cancelSatellitePass(satellitePassId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * This method is used for booking passes for the current user. It requires a Pass Prediction obtained from the Pass Prediction API to be provided in the body of the request. Pass predictions are valid for 20 minutes.If the prediction has expired or is ivalid, a 400 Bad Request response will be returned.
     * @summary Book a Satellite Pass
     * @param {PassPrediction} passPrediction
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SatellitePassesApi
     */
    SatellitePassesApi.prototype.createSatellitePass = function (passPrediction, options) {
        var _this = this;
        return exports.SatellitePassesApiFp(this.configuration).createSatellitePass(passPrediction, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * This method returns a filtered list of Satellite Passes. Satellite Passes could be filtered by Satellite, Ground Station and Status
     * @summary Get Filtered Satellite Passes
     * @param {string} [satelliteId] Specifies the satellite for which to get Satellite Passes
     * @param {Array<string>} [groundStation] Specifies the Ground Station(s) of the Satellite Passes to get.
     * @param {'PREDICTION' | 'SCHEDULED' | 'IN_PROGRESS' | 'FINISHED_SUCCESSFULLY' | 'FINISHED_WITH_ERRORS' | 'CANCELED' | 'MISSED'} [status] Specifies the Status (or part of the Status) of the Satellite Passes to get
     * @param {number} [fromTime] Filter to only return entries that were received after the specified time (inclusive)
     * @param {number} [toTime] Filter to only return entries that were received before the specified time (exclusive)
     * @param {string} [lastEvaluatedItem] Specified the ID of the last item that was returned from the last page
     * @param {number} [pageSizeLimit] Specifies the maximum number of results per page. Defaults to 100
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SatellitePassesApi
     */
    SatellitePassesApi.prototype.getFilteredSatellitePasses = function (satelliteId, groundStation, status, fromTime, toTime, lastEvaluatedItem, pageSizeLimit, options) {
        var _this = this;
        return exports.SatellitePassesApiFp(this.configuration).getFilteredSatellitePasses(satelliteId, groundStation, status, fromTime, toTime, lastEvaluatedItem, pageSizeLimit, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * This method returns a pass by ID for the current user
     * @summary Get a Satellite Pass by ID
     * @param {string} satellitePassId ID of the Satellite Pass to get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SatellitePassesApi
     */
    SatellitePassesApi.prototype.getSatellitePassById = function (satellitePassId, options) {
        var _this = this;
        return exports.SatellitePassesApiFp(this.configuration).getSatellitePassById(satellitePassId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * This method records a lof of the satellite pass
     * @summary Record a Satellite Pass Log
     * @param {string} satellitePassId ID of the Satellite Pass to update
     * @param {any} payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SatellitePassesApi
     */
    SatellitePassesApi.prototype.recordSatellitePassLog = function (satellitePassId, payload, options) {
        var _this = this;
        return exports.SatellitePassesApiFp(this.configuration).recordSatellitePassLog(satellitePassId, payload, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * This method updates a satellite pass by ID for the current user
     * @summary Update a Satellite Pass
     * @param {string} satellitePassId ID of the Satellite Pass to update
     * @param {SatellitePass} satellitePass
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SatellitePassesApi
     */
    SatellitePassesApi.prototype.updateSatellitePassById = function (satellitePassId, satellitePass, options) {
        var _this = this;
        return exports.SatellitePassesApiFp(this.configuration).updateSatellitePassById(satellitePassId, satellitePass, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return SatellitePassesApi;
}(base_1.BaseAPI));
exports.SatellitePassesApi = SatellitePassesApi;
/**
 * SatelliteSubsystemsApi - axios parameter creator
 * @export
 */
exports.SatelliteSubsystemsApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * This method creates a new Satellite Subsystem for the current user.
         * @summary Create a Satellite Subsystem
         * @param {SatelliteSubsystem} satelliteSubsystem
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSatelliteSubsystem: function (satelliteSubsystem, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'satelliteSubsystem' is not null or undefined
                            common_1.assertParamExists('createSatelliteSubsystem', 'satelliteSubsystem', satelliteSubsystem);
                            localVarPath = "/satellite-subsystems";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication MyEnduroSat required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)];
                        case 1:
                            // authentication MyEnduroSat required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(satelliteSubsystem, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * This method deletes a Satellite Subsystem by ID
         * @summary Delete a Satellite Subsystem
         * @param {string} satelliteSubsystemId ID of the Satellite Subsystem to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSatelliteSubsystemById: function (satelliteSubsystemId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'satelliteSubsystemId' is not null or undefined
                            common_1.assertParamExists('deleteSatelliteSubsystemById', 'satelliteSubsystemId', satelliteSubsystemId);
                            localVarPath = "/satellite-subsystems/{satelliteSubsystemId}"
                                .replace("{" + "satelliteSubsystemId" + "}", encodeURIComponent(String(satelliteSubsystemId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication MyEnduroSat required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)];
                        case 1:
                            // authentication MyEnduroSat required
                            // oauth required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * This method returns a filtered list of Satellite Subsystems. Satellite Subsystems could be filtered by Satellite, Name and Type. Maximum count of the returned items is 10000.
         * @summary Get all Satellite Subsystems
         * @param {string} [satelliteId] Specifies the Satellite for which the Subsystems are applicable
         * @param {string} [name] Specifies the name (or part of the name) of the Subsystem
         * @param {string} [type] Specifies the type (or part of the type) of the Subsystem
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilteredSatelliteSubsystems: function (satelliteId, name, type, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/satellite-subsystems";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication MyEnduroSat required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)];
                        case 1:
                            // authentication MyEnduroSat required
                            // oauth required
                            _a.sent();
                            if (satelliteId !== undefined) {
                                localVarQueryParameter['satelliteId'] = satelliteId;
                            }
                            if (name !== undefined) {
                                localVarQueryParameter['name'] = name;
                            }
                            if (type !== undefined) {
                                localVarQueryParameter['type'] = type;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * This method returns a Satellite Subsystem by ID
         * @summary Get a Satellite Subsystem by ID
         * @param {string} satelliteSubsystemId ID of the Satellite Subsystem to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSatelliteSubsystemById: function (satelliteSubsystemId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'satelliteSubsystemId' is not null or undefined
                            common_1.assertParamExists('getSatelliteSubsystemById', 'satelliteSubsystemId', satelliteSubsystemId);
                            localVarPath = "/satellite-subsystems/{satelliteSubsystemId}"
                                .replace("{" + "satelliteSubsystemId" + "}", encodeURIComponent(String(satelliteSubsystemId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication MyEnduroSat required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)];
                        case 1:
                            // authentication MyEnduroSat required
                            // oauth required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * This method updates a Satellite Subsystem by ID
         * @summary Update a Satellite Subsystem
         * @param {string} satelliteSubsystemId ID of the Satellite Subsystem to update
         * @param {SatelliteSubsystem} satelliteSubsystem
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSatelliteSubsystemById: function (satelliteSubsystemId, satelliteSubsystem, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'satelliteSubsystemId' is not null or undefined
                            common_1.assertParamExists('updateSatelliteSubsystemById', 'satelliteSubsystemId', satelliteSubsystemId);
                            // verify required parameter 'satelliteSubsystem' is not null or undefined
                            common_1.assertParamExists('updateSatelliteSubsystemById', 'satelliteSubsystem', satelliteSubsystem);
                            localVarPath = "/satellite-subsystems/{satelliteSubsystemId}"
                                .replace("{" + "satelliteSubsystemId" + "}", encodeURIComponent(String(satelliteSubsystemId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication MyEnduroSat required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)];
                        case 1:
                            // authentication MyEnduroSat required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(satelliteSubsystem, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
/**
 * SatelliteSubsystemsApi - functional programming interface
 * @export
 */
exports.SatelliteSubsystemsApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.SatelliteSubsystemsApiAxiosParamCreator(configuration);
    return {
        /**
         * This method creates a new Satellite Subsystem for the current user.
         * @summary Create a Satellite Subsystem
         * @param {SatelliteSubsystem} satelliteSubsystem
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSatelliteSubsystem: function (satelliteSubsystem, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createSatelliteSubsystem(satelliteSubsystem, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * This method deletes a Satellite Subsystem by ID
         * @summary Delete a Satellite Subsystem
         * @param {string} satelliteSubsystemId ID of the Satellite Subsystem to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSatelliteSubsystemById: function (satelliteSubsystemId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteSatelliteSubsystemById(satelliteSubsystemId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * This method returns a filtered list of Satellite Subsystems. Satellite Subsystems could be filtered by Satellite, Name and Type. Maximum count of the returned items is 10000.
         * @summary Get all Satellite Subsystems
         * @param {string} [satelliteId] Specifies the Satellite for which the Subsystems are applicable
         * @param {string} [name] Specifies the name (or part of the name) of the Subsystem
         * @param {string} [type] Specifies the type (or part of the type) of the Subsystem
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilteredSatelliteSubsystems: function (satelliteId, name, type, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getFilteredSatelliteSubsystems(satelliteId, name, type, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * This method returns a Satellite Subsystem by ID
         * @summary Get a Satellite Subsystem by ID
         * @param {string} satelliteSubsystemId ID of the Satellite Subsystem to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSatelliteSubsystemById: function (satelliteSubsystemId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getSatelliteSubsystemById(satelliteSubsystemId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * This method updates a Satellite Subsystem by ID
         * @summary Update a Satellite Subsystem
         * @param {string} satelliteSubsystemId ID of the Satellite Subsystem to update
         * @param {SatelliteSubsystem} satelliteSubsystem
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSatelliteSubsystemById: function (satelliteSubsystemId, satelliteSubsystem, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateSatelliteSubsystemById(satelliteSubsystemId, satelliteSubsystem, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * SatelliteSubsystemsApi - factory interface
 * @export
 */
exports.SatelliteSubsystemsApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.SatelliteSubsystemsApiFp(configuration);
    return {
        /**
         * This method creates a new Satellite Subsystem for the current user.
         * @summary Create a Satellite Subsystem
         * @param {SatelliteSubsystem} satelliteSubsystem
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSatelliteSubsystem: function (satelliteSubsystem, options) {
            return localVarFp.createSatelliteSubsystem(satelliteSubsystem, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * This method deletes a Satellite Subsystem by ID
         * @summary Delete a Satellite Subsystem
         * @param {string} satelliteSubsystemId ID of the Satellite Subsystem to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSatelliteSubsystemById: function (satelliteSubsystemId, options) {
            return localVarFp.deleteSatelliteSubsystemById(satelliteSubsystemId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * This method returns a filtered list of Satellite Subsystems. Satellite Subsystems could be filtered by Satellite, Name and Type. Maximum count of the returned items is 10000.
         * @summary Get all Satellite Subsystems
         * @param {string} [satelliteId] Specifies the Satellite for which the Subsystems are applicable
         * @param {string} [name] Specifies the name (or part of the name) of the Subsystem
         * @param {string} [type] Specifies the type (or part of the type) of the Subsystem
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilteredSatelliteSubsystems: function (satelliteId, name, type, options) {
            return localVarFp.getFilteredSatelliteSubsystems(satelliteId, name, type, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * This method returns a Satellite Subsystem by ID
         * @summary Get a Satellite Subsystem by ID
         * @param {string} satelliteSubsystemId ID of the Satellite Subsystem to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSatelliteSubsystemById: function (satelliteSubsystemId, options) {
            return localVarFp.getSatelliteSubsystemById(satelliteSubsystemId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * This method updates a Satellite Subsystem by ID
         * @summary Update a Satellite Subsystem
         * @param {string} satelliteSubsystemId ID of the Satellite Subsystem to update
         * @param {SatelliteSubsystem} satelliteSubsystem
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSatelliteSubsystemById: function (satelliteSubsystemId, satelliteSubsystem, options) {
            return localVarFp.updateSatelliteSubsystemById(satelliteSubsystemId, satelliteSubsystem, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * SatelliteSubsystemsApi - object-oriented interface
 * @export
 * @class SatelliteSubsystemsApi
 * @extends {BaseAPI}
 */
var SatelliteSubsystemsApi = /** @class */ (function (_super) {
    __extends(SatelliteSubsystemsApi, _super);
    function SatelliteSubsystemsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * This method creates a new Satellite Subsystem for the current user.
     * @summary Create a Satellite Subsystem
     * @param {SatelliteSubsystem} satelliteSubsystem
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SatelliteSubsystemsApi
     */
    SatelliteSubsystemsApi.prototype.createSatelliteSubsystem = function (satelliteSubsystem, options) {
        var _this = this;
        return exports.SatelliteSubsystemsApiFp(this.configuration).createSatelliteSubsystem(satelliteSubsystem, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * This method deletes a Satellite Subsystem by ID
     * @summary Delete a Satellite Subsystem
     * @param {string} satelliteSubsystemId ID of the Satellite Subsystem to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SatelliteSubsystemsApi
     */
    SatelliteSubsystemsApi.prototype.deleteSatelliteSubsystemById = function (satelliteSubsystemId, options) {
        var _this = this;
        return exports.SatelliteSubsystemsApiFp(this.configuration).deleteSatelliteSubsystemById(satelliteSubsystemId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * This method returns a filtered list of Satellite Subsystems. Satellite Subsystems could be filtered by Satellite, Name and Type. Maximum count of the returned items is 10000.
     * @summary Get all Satellite Subsystems
     * @param {string} [satelliteId] Specifies the Satellite for which the Subsystems are applicable
     * @param {string} [name] Specifies the name (or part of the name) of the Subsystem
     * @param {string} [type] Specifies the type (or part of the type) of the Subsystem
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SatelliteSubsystemsApi
     */
    SatelliteSubsystemsApi.prototype.getFilteredSatelliteSubsystems = function (satelliteId, name, type, options) {
        var _this = this;
        return exports.SatelliteSubsystemsApiFp(this.configuration).getFilteredSatelliteSubsystems(satelliteId, name, type, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * This method returns a Satellite Subsystem by ID
     * @summary Get a Satellite Subsystem by ID
     * @param {string} satelliteSubsystemId ID of the Satellite Subsystem to get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SatelliteSubsystemsApi
     */
    SatelliteSubsystemsApi.prototype.getSatelliteSubsystemById = function (satelliteSubsystemId, options) {
        var _this = this;
        return exports.SatelliteSubsystemsApiFp(this.configuration).getSatelliteSubsystemById(satelliteSubsystemId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * This method updates a Satellite Subsystem by ID
     * @summary Update a Satellite Subsystem
     * @param {string} satelliteSubsystemId ID of the Satellite Subsystem to update
     * @param {SatelliteSubsystem} satelliteSubsystem
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SatelliteSubsystemsApi
     */
    SatelliteSubsystemsApi.prototype.updateSatelliteSubsystemById = function (satelliteSubsystemId, satelliteSubsystem, options) {
        var _this = this;
        return exports.SatelliteSubsystemsApiFp(this.configuration).updateSatelliteSubsystemById(satelliteSubsystemId, satelliteSubsystem, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return SatelliteSubsystemsApi;
}(base_1.BaseAPI));
exports.SatelliteSubsystemsApi = SatelliteSubsystemsApi;
/**
 * SatelliteTelecommandsApi - axios parameter creator
 * @export
 */
exports.SatelliteTelecommandsApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * This method creates new satellite telecommands for the current user.
         * @summary Create a Satellite Telecommand
         * @param {Telecommand} telecommand
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTelecommand: function (telecommand, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'telecommand' is not null or undefined
                            common_1.assertParamExists('createTelecommand', 'telecommand', telecommand);
                            localVarPath = "/satellite-telecommands";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication MyEnduroSat required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)];
                        case 1:
                            // authentication MyEnduroSat required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(telecommand, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * This method creates new satellite telecommands with payloads for the current user.
         * @summary Create a Satellite Telecommand with Payload
         * @param {any} payload
         * @param {Telecommand} telecommand
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTelecommandWithPayload: function (payload, telecommand, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'payload' is not null or undefined
                            common_1.assertParamExists('createTelecommandWithPayload', 'payload', payload);
                            // verify required parameter 'telecommand' is not null or undefined
                            common_1.assertParamExists('createTelecommandWithPayload', 'telecommand', telecommand);
                            localVarPath = "/satellite-telecommands/with-payload";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
                            // authentication MyEnduroSat required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)];
                        case 1:
                            // authentication MyEnduroSat required
                            // oauth required
                            _a.sent();
                            if (payload !== undefined) {
                                localVarFormParams.append('payload', payload);
                            }
                            if (telecommand !== undefined) {
                                localVarFormParams.append('telecommand', new Blob([JSON.stringify(telecommand)], { type: "application/json", }));
                            }
                            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = localVarFormParams;
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * This method deletes a Satellite Telecommand by ID for the current user
         * @summary Delete a Satellite Telecommand
         * @param {string} telecommandId ID of the Satellite Telecommand to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTelecommandById: function (telecommandId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'telecommandId' is not null or undefined
                            common_1.assertParamExists('deleteTelecommandById', 'telecommandId', telecommandId);
                            localVarPath = "/satellite-telecommands/{telecommandId}"
                                .replace("{" + "telecommandId" + "}", encodeURIComponent(String(telecommandId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication MyEnduroSat required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)];
                        case 1:
                            // authentication MyEnduroSat required
                            // oauth required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * This method returns a filtered list of telecommands for a satellite. Telecommands could be filtered by Satellite, Satellite Subsystem, Requested Satellite Pass, Executed Satellite Pass and Status
         * @summary Get Filtered Telecommands
         * @param {string} [satelliteId] Specifies the satellite for which to get telecommands
         * @param {string} [satelliteSubsystemId] Specifies the satellite subsystem (or part of the subsystem) for which to get telecommands
         * @param {string} [name] Specifies the name or part of the name (or part of the name) of the telecommand
         * @param {string} [requestedSatellitePass] Specifies the satellite pass (or part of the pass) for which the telecommand was originally scheduled
         * @param {string} [executedSatellitePass] Specifies the satellite pass (or part of the pass) during which the telecommand was sent to the satellite
         * @param {'DRAFT' | 'SCHEDULED' | 'REQUESTED' | 'TESTED' | 'SENDING' | 'SENT' | 'FAILED_TO_SEND' | 'RECEIVED' | 'EXECUTING' | 'SUCCESSFUL' | 'FAILED' | 'CANCELED' | 'TEMPLATE'} [status] Specifies the status (or part of the status) of the telecommand
         * @param {string} [lastEvaluatedItem] Specified the ID of the last item that was returned from the last page
         * @param {number} [pageSizeLimit] Specifies the maximum number of results per page. Defaults to 100
         * @param {string} [dataQuery] Allows you to filter telecommands data using a query that supports the following comparison operations: \&#39;&gt;\&#39; (greater than), \&#39;&#x3D;&#x3D;\&#39;(equal to), \&#39;&lt;\&#39;(less than), \&#39;&gt;&#x3D;\&#39; (greater than or equal to), \&#39;&lt;&#x3D;\&#39; (less than or equal to). Supported logical operators are \&#39;and\&#39; and \&#39;or\&#39;. Fields and values are case-sensitive. Example: cmd.name &#x3D;&#x3D; Raise and (cmd.value &#x3D;&#x3D; 112 or cmd.value &#x3D;&#x3D; 113)
         * @param {string} [projectionExpression] Allows you to specify a subset of attributes to be returned. By default, all attributes are returned. Projection expression supports all properties of the Telecommand. Example: name, fpHeader.SatId, fpHeader.FuncId, data.types[0]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilteredTelecommands: function (satelliteId, satelliteSubsystemId, name, requestedSatellitePass, executedSatellitePass, status, lastEvaluatedItem, pageSizeLimit, dataQuery, projectionExpression, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/satellite-telecommands";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication MyEnduroSat required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)];
                        case 1:
                            // authentication MyEnduroSat required
                            // oauth required
                            _a.sent();
                            if (satelliteId !== undefined) {
                                localVarQueryParameter['satelliteId'] = satelliteId;
                            }
                            if (satelliteSubsystemId !== undefined) {
                                localVarQueryParameter['satelliteSubsystemId'] = satelliteSubsystemId;
                            }
                            if (name !== undefined) {
                                localVarQueryParameter['name'] = name;
                            }
                            if (requestedSatellitePass !== undefined) {
                                localVarQueryParameter['requestedSatellitePass'] = requestedSatellitePass;
                            }
                            if (executedSatellitePass !== undefined) {
                                localVarQueryParameter['executedSatellitePass'] = executedSatellitePass;
                            }
                            if (status !== undefined) {
                                localVarQueryParameter['status'] = status;
                            }
                            if (lastEvaluatedItem !== undefined) {
                                localVarQueryParameter['lastEvaluatedItem'] = lastEvaluatedItem;
                            }
                            if (pageSizeLimit !== undefined) {
                                localVarQueryParameter['pageSizeLimit'] = pageSizeLimit;
                            }
                            if (dataQuery !== undefined) {
                                localVarQueryParameter['dataQuery'] = dataQuery;
                            }
                            if (projectionExpression !== undefined) {
                                localVarQueryParameter['projectionExpression'] = projectionExpression;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * This method returns a Satellite Telecommand by ID for the current user
         * @summary Get a Satellite Telecommand by ID
         * @param {string} telecommandId ID of the Satellite Telecommand to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTelecommandById: function (telecommandId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'telecommandId' is not null or undefined
                            common_1.assertParamExists('getTelecommandById', 'telecommandId', telecommandId);
                            localVarPath = "/satellite-telecommands/{telecommandId}"
                                .replace("{" + "telecommandId" + "}", encodeURIComponent(String(telecommandId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication MyEnduroSat required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)];
                        case 1:
                            // authentication MyEnduroSat required
                            // oauth required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * This method updates a Satellite Telecommand by ID for the current user
         * @summary Update a Satellite Telecommand
         * @param {string} telecommandId ID of the Satellite Telecommand to update
         * @param {Telecommand} telecommand
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTelecommandById: function (telecommandId, telecommand, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'telecommandId' is not null or undefined
                            common_1.assertParamExists('updateTelecommandById', 'telecommandId', telecommandId);
                            // verify required parameter 'telecommand' is not null or undefined
                            common_1.assertParamExists('updateTelecommandById', 'telecommand', telecommand);
                            localVarPath = "/satellite-telecommands/{telecommandId}"
                                .replace("{" + "telecommandId" + "}", encodeURIComponent(String(telecommandId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication MyEnduroSat required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)];
                        case 1:
                            // authentication MyEnduroSat required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(telecommand, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
/**
 * SatelliteTelecommandsApi - functional programming interface
 * @export
 */
exports.SatelliteTelecommandsApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.SatelliteTelecommandsApiAxiosParamCreator(configuration);
    return {
        /**
         * This method creates new satellite telecommands for the current user.
         * @summary Create a Satellite Telecommand
         * @param {Telecommand} telecommand
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTelecommand: function (telecommand, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createTelecommand(telecommand, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * This method creates new satellite telecommands with payloads for the current user.
         * @summary Create a Satellite Telecommand with Payload
         * @param {any} payload
         * @param {Telecommand} telecommand
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTelecommandWithPayload: function (payload, telecommand, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createTelecommandWithPayload(payload, telecommand, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * This method deletes a Satellite Telecommand by ID for the current user
         * @summary Delete a Satellite Telecommand
         * @param {string} telecommandId ID of the Satellite Telecommand to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTelecommandById: function (telecommandId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteTelecommandById(telecommandId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * This method returns a filtered list of telecommands for a satellite. Telecommands could be filtered by Satellite, Satellite Subsystem, Requested Satellite Pass, Executed Satellite Pass and Status
         * @summary Get Filtered Telecommands
         * @param {string} [satelliteId] Specifies the satellite for which to get telecommands
         * @param {string} [satelliteSubsystemId] Specifies the satellite subsystem (or part of the subsystem) for which to get telecommands
         * @param {string} [name] Specifies the name or part of the name (or part of the name) of the telecommand
         * @param {string} [requestedSatellitePass] Specifies the satellite pass (or part of the pass) for which the telecommand was originally scheduled
         * @param {string} [executedSatellitePass] Specifies the satellite pass (or part of the pass) during which the telecommand was sent to the satellite
         * @param {'DRAFT' | 'SCHEDULED' | 'REQUESTED' | 'TESTED' | 'SENDING' | 'SENT' | 'FAILED_TO_SEND' | 'RECEIVED' | 'EXECUTING' | 'SUCCESSFUL' | 'FAILED' | 'CANCELED' | 'TEMPLATE'} [status] Specifies the status (or part of the status) of the telecommand
         * @param {string} [lastEvaluatedItem] Specified the ID of the last item that was returned from the last page
         * @param {number} [pageSizeLimit] Specifies the maximum number of results per page. Defaults to 100
         * @param {string} [dataQuery] Allows you to filter telecommands data using a query that supports the following comparison operations: \&#39;&gt;\&#39; (greater than), \&#39;&#x3D;&#x3D;\&#39;(equal to), \&#39;&lt;\&#39;(less than), \&#39;&gt;&#x3D;\&#39; (greater than or equal to), \&#39;&lt;&#x3D;\&#39; (less than or equal to). Supported logical operators are \&#39;and\&#39; and \&#39;or\&#39;. Fields and values are case-sensitive. Example: cmd.name &#x3D;&#x3D; Raise and (cmd.value &#x3D;&#x3D; 112 or cmd.value &#x3D;&#x3D; 113)
         * @param {string} [projectionExpression] Allows you to specify a subset of attributes to be returned. By default, all attributes are returned. Projection expression supports all properties of the Telecommand. Example: name, fpHeader.SatId, fpHeader.FuncId, data.types[0]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilteredTelecommands: function (satelliteId, satelliteSubsystemId, name, requestedSatellitePass, executedSatellitePass, status, lastEvaluatedItem, pageSizeLimit, dataQuery, projectionExpression, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getFilteredTelecommands(satelliteId, satelliteSubsystemId, name, requestedSatellitePass, executedSatellitePass, status, lastEvaluatedItem, pageSizeLimit, dataQuery, projectionExpression, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * This method returns a Satellite Telecommand by ID for the current user
         * @summary Get a Satellite Telecommand by ID
         * @param {string} telecommandId ID of the Satellite Telecommand to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTelecommandById: function (telecommandId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getTelecommandById(telecommandId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * This method updates a Satellite Telecommand by ID for the current user
         * @summary Update a Satellite Telecommand
         * @param {string} telecommandId ID of the Satellite Telecommand to update
         * @param {Telecommand} telecommand
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTelecommandById: function (telecommandId, telecommand, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateTelecommandById(telecommandId, telecommand, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * SatelliteTelecommandsApi - factory interface
 * @export
 */
exports.SatelliteTelecommandsApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.SatelliteTelecommandsApiFp(configuration);
    return {
        /**
         * This method creates new satellite telecommands for the current user.
         * @summary Create a Satellite Telecommand
         * @param {Telecommand} telecommand
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTelecommand: function (telecommand, options) {
            return localVarFp.createTelecommand(telecommand, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * This method creates new satellite telecommands with payloads for the current user.
         * @summary Create a Satellite Telecommand with Payload
         * @param {any} payload
         * @param {Telecommand} telecommand
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTelecommandWithPayload: function (payload, telecommand, options) {
            return localVarFp.createTelecommandWithPayload(payload, telecommand, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * This method deletes a Satellite Telecommand by ID for the current user
         * @summary Delete a Satellite Telecommand
         * @param {string} telecommandId ID of the Satellite Telecommand to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTelecommandById: function (telecommandId, options) {
            return localVarFp.deleteTelecommandById(telecommandId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * This method returns a filtered list of telecommands for a satellite. Telecommands could be filtered by Satellite, Satellite Subsystem, Requested Satellite Pass, Executed Satellite Pass and Status
         * @summary Get Filtered Telecommands
         * @param {string} [satelliteId] Specifies the satellite for which to get telecommands
         * @param {string} [satelliteSubsystemId] Specifies the satellite subsystem (or part of the subsystem) for which to get telecommands
         * @param {string} [name] Specifies the name or part of the name (or part of the name) of the telecommand
         * @param {string} [requestedSatellitePass] Specifies the satellite pass (or part of the pass) for which the telecommand was originally scheduled
         * @param {string} [executedSatellitePass] Specifies the satellite pass (or part of the pass) during which the telecommand was sent to the satellite
         * @param {'DRAFT' | 'SCHEDULED' | 'REQUESTED' | 'TESTED' | 'SENDING' | 'SENT' | 'FAILED_TO_SEND' | 'RECEIVED' | 'EXECUTING' | 'SUCCESSFUL' | 'FAILED' | 'CANCELED' | 'TEMPLATE'} [status] Specifies the status (or part of the status) of the telecommand
         * @param {string} [lastEvaluatedItem] Specified the ID of the last item that was returned from the last page
         * @param {number} [pageSizeLimit] Specifies the maximum number of results per page. Defaults to 100
         * @param {string} [dataQuery] Allows you to filter telecommands data using a query that supports the following comparison operations: \&#39;&gt;\&#39; (greater than), \&#39;&#x3D;&#x3D;\&#39;(equal to), \&#39;&lt;\&#39;(less than), \&#39;&gt;&#x3D;\&#39; (greater than or equal to), \&#39;&lt;&#x3D;\&#39; (less than or equal to). Supported logical operators are \&#39;and\&#39; and \&#39;or\&#39;. Fields and values are case-sensitive. Example: cmd.name &#x3D;&#x3D; Raise and (cmd.value &#x3D;&#x3D; 112 or cmd.value &#x3D;&#x3D; 113)
         * @param {string} [projectionExpression] Allows you to specify a subset of attributes to be returned. By default, all attributes are returned. Projection expression supports all properties of the Telecommand. Example: name, fpHeader.SatId, fpHeader.FuncId, data.types[0]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilteredTelecommands: function (satelliteId, satelliteSubsystemId, name, requestedSatellitePass, executedSatellitePass, status, lastEvaluatedItem, pageSizeLimit, dataQuery, projectionExpression, options) {
            return localVarFp.getFilteredTelecommands(satelliteId, satelliteSubsystemId, name, requestedSatellitePass, executedSatellitePass, status, lastEvaluatedItem, pageSizeLimit, dataQuery, projectionExpression, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * This method returns a Satellite Telecommand by ID for the current user
         * @summary Get a Satellite Telecommand by ID
         * @param {string} telecommandId ID of the Satellite Telecommand to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTelecommandById: function (telecommandId, options) {
            return localVarFp.getTelecommandById(telecommandId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * This method updates a Satellite Telecommand by ID for the current user
         * @summary Update a Satellite Telecommand
         * @param {string} telecommandId ID of the Satellite Telecommand to update
         * @param {Telecommand} telecommand
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTelecommandById: function (telecommandId, telecommand, options) {
            return localVarFp.updateTelecommandById(telecommandId, telecommand, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * SatelliteTelecommandsApi - object-oriented interface
 * @export
 * @class SatelliteTelecommandsApi
 * @extends {BaseAPI}
 */
var SatelliteTelecommandsApi = /** @class */ (function (_super) {
    __extends(SatelliteTelecommandsApi, _super);
    function SatelliteTelecommandsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * This method creates new satellite telecommands for the current user.
     * @summary Create a Satellite Telecommand
     * @param {Telecommand} telecommand
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SatelliteTelecommandsApi
     */
    SatelliteTelecommandsApi.prototype.createTelecommand = function (telecommand, options) {
        var _this = this;
        return exports.SatelliteTelecommandsApiFp(this.configuration).createTelecommand(telecommand, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * This method creates new satellite telecommands with payloads for the current user.
     * @summary Create a Satellite Telecommand with Payload
     * @param {any} payload
     * @param {Telecommand} telecommand
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SatelliteTelecommandsApi
     */
    SatelliteTelecommandsApi.prototype.createTelecommandWithPayload = function (payload, telecommand, options) {
        var _this = this;
        return exports.SatelliteTelecommandsApiFp(this.configuration).createTelecommandWithPayload(payload, telecommand, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * This method deletes a Satellite Telecommand by ID for the current user
     * @summary Delete a Satellite Telecommand
     * @param {string} telecommandId ID of the Satellite Telecommand to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SatelliteTelecommandsApi
     */
    SatelliteTelecommandsApi.prototype.deleteTelecommandById = function (telecommandId, options) {
        var _this = this;
        return exports.SatelliteTelecommandsApiFp(this.configuration).deleteTelecommandById(telecommandId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * This method returns a filtered list of telecommands for a satellite. Telecommands could be filtered by Satellite, Satellite Subsystem, Requested Satellite Pass, Executed Satellite Pass and Status
     * @summary Get Filtered Telecommands
     * @param {string} [satelliteId] Specifies the satellite for which to get telecommands
     * @param {string} [satelliteSubsystemId] Specifies the satellite subsystem (or part of the subsystem) for which to get telecommands
     * @param {string} [name] Specifies the name or part of the name (or part of the name) of the telecommand
     * @param {string} [requestedSatellitePass] Specifies the satellite pass (or part of the pass) for which the telecommand was originally scheduled
     * @param {string} [executedSatellitePass] Specifies the satellite pass (or part of the pass) during which the telecommand was sent to the satellite
     * @param {'DRAFT' | 'SCHEDULED' | 'REQUESTED' | 'TESTED' | 'SENDING' | 'SENT' | 'FAILED_TO_SEND' | 'RECEIVED' | 'EXECUTING' | 'SUCCESSFUL' | 'FAILED' | 'CANCELED' | 'TEMPLATE'} [status] Specifies the status (or part of the status) of the telecommand
     * @param {string} [lastEvaluatedItem] Specified the ID of the last item that was returned from the last page
     * @param {number} [pageSizeLimit] Specifies the maximum number of results per page. Defaults to 100
     * @param {string} [dataQuery] Allows you to filter telecommands data using a query that supports the following comparison operations: \&#39;&gt;\&#39; (greater than), \&#39;&#x3D;&#x3D;\&#39;(equal to), \&#39;&lt;\&#39;(less than), \&#39;&gt;&#x3D;\&#39; (greater than or equal to), \&#39;&lt;&#x3D;\&#39; (less than or equal to). Supported logical operators are \&#39;and\&#39; and \&#39;or\&#39;. Fields and values are case-sensitive. Example: cmd.name &#x3D;&#x3D; Raise and (cmd.value &#x3D;&#x3D; 112 or cmd.value &#x3D;&#x3D; 113)
     * @param {string} [projectionExpression] Allows you to specify a subset of attributes to be returned. By default, all attributes are returned. Projection expression supports all properties of the Telecommand. Example: name, fpHeader.SatId, fpHeader.FuncId, data.types[0]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SatelliteTelecommandsApi
     */
    SatelliteTelecommandsApi.prototype.getFilteredTelecommands = function (satelliteId, satelliteSubsystemId, name, requestedSatellitePass, executedSatellitePass, status, lastEvaluatedItem, pageSizeLimit, dataQuery, projectionExpression, options) {
        var _this = this;
        return exports.SatelliteTelecommandsApiFp(this.configuration).getFilteredTelecommands(satelliteId, satelliteSubsystemId, name, requestedSatellitePass, executedSatellitePass, status, lastEvaluatedItem, pageSizeLimit, dataQuery, projectionExpression, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * This method returns a Satellite Telecommand by ID for the current user
     * @summary Get a Satellite Telecommand by ID
     * @param {string} telecommandId ID of the Satellite Telecommand to get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SatelliteTelecommandsApi
     */
    SatelliteTelecommandsApi.prototype.getTelecommandById = function (telecommandId, options) {
        var _this = this;
        return exports.SatelliteTelecommandsApiFp(this.configuration).getTelecommandById(telecommandId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * This method updates a Satellite Telecommand by ID for the current user
     * @summary Update a Satellite Telecommand
     * @param {string} telecommandId ID of the Satellite Telecommand to update
     * @param {Telecommand} telecommand
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SatelliteTelecommandsApi
     */
    SatelliteTelecommandsApi.prototype.updateTelecommandById = function (telecommandId, telecommand, options) {
        var _this = this;
        return exports.SatelliteTelecommandsApiFp(this.configuration).updateTelecommandById(telecommandId, telecommand, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return SatelliteTelecommandsApi;
}(base_1.BaseAPI));
exports.SatelliteTelecommandsApi = SatelliteTelecommandsApi;
/**
 * SatelliteTelemetryApi - axios parameter creator
 * @export
 */
exports.SatelliteTelemetryApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * This method returns a filtered list of historical telemetry values for a satellite. Telemetry could be filtered by Satellite, Satellite Pass and time received
         * @summary Get Historical Telemetry
         * @param {string} [name] Specifies the name (or part of the name) of the telemetry
         * @param {string} [satelliteId] Specifies the satellite for which to get telemetry
         * @param {string} [satelliteSubsystemId] Specifies the satellite subsystem (or part of the subsytem) for which to get telemetry
         * @param {string} [passId] Specifies the satellite pass (or part of the pass) during which telemetry was aquired
         * @param {number} [fromTime] Filter to only return entries that were received after the specified time (inclusive)
         * @param {number} [toTime] Filter to only return entries that were received before the specified time (exclusive)
         * @param {string} [missionDatabase] Filter by mission database (or part of the mission database)
         * @param {string} [telecommandRequest] Filter by telecommand request
         * @param {string} [lastEvaluatedItem] Specified the ID of the last item that was returned from the last page
         * @param {number} [pageSizeLimit] Specifies the maximum number of results per page. Defaults to 100
         * @param {string} [dataQuery] Allows you to filter telemetry data using a query that supports the following comparison operations: \&#39;&gt;\&#39; (greater than), \&#39;&#x3D;&#x3D;\&#39;(equal to), \&#39;&lt;\&#39;(less than), \&#39;&gt;&#x3D;\&#39; (greater than or equal to), \&#39;&lt;&#x3D;\&#39; (less than or equal to). Supported logical operators are \&#39;and\&#39; and \&#39;or\&#39;. Fields and values are case-sensitive. Example: fpHeader.SatId &#x3D;&#x3D; 1 and (fpHeader.FuncId &#x3D;&#x3D; 2 or fpHeader.FuncId &#x3D;&#x3D; 3)
         * @param {string} [projectionExpression] Allows you to specify a subset of attributes to be returned. By default, all attributes are returned. Projection expression supports all properties of the Telemetry. Example: fpHeader.SatId, fpHeader.FuncId, data.types[0]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilteredTelemetryEntries: function (name, satelliteId, satelliteSubsystemId, passId, fromTime, toTime, missionDatabase, telecommandRequest, lastEvaluatedItem, pageSizeLimit, dataQuery, projectionExpression, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/satellite-telemetry";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication MyEnduroSat required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)];
                        case 1:
                            // authentication MyEnduroSat required
                            // oauth required
                            _a.sent();
                            if (name !== undefined) {
                                localVarQueryParameter['name'] = name;
                            }
                            if (satelliteId !== undefined) {
                                localVarQueryParameter['satelliteId'] = satelliteId;
                            }
                            if (satelliteSubsystemId !== undefined) {
                                localVarQueryParameter['satelliteSubsystemId'] = satelliteSubsystemId;
                            }
                            if (passId !== undefined) {
                                localVarQueryParameter['passId'] = passId;
                            }
                            if (fromTime !== undefined) {
                                localVarQueryParameter['fromTime'] = fromTime;
                            }
                            if (toTime !== undefined) {
                                localVarQueryParameter['toTime'] = toTime;
                            }
                            if (missionDatabase !== undefined) {
                                localVarQueryParameter['missionDatabase'] = missionDatabase;
                            }
                            if (telecommandRequest !== undefined) {
                                localVarQueryParameter['telecommandRequest'] = telecommandRequest;
                            }
                            if (lastEvaluatedItem !== undefined) {
                                localVarQueryParameter['lastEvaluatedItem'] = lastEvaluatedItem;
                            }
                            if (pageSizeLimit !== undefined) {
                                localVarQueryParameter['pageSizeLimit'] = pageSizeLimit;
                            }
                            if (dataQuery !== undefined) {
                                localVarQueryParameter['dataQuery'] = dataQuery;
                            }
                            if (projectionExpression !== undefined) {
                                localVarQueryParameter['projectionExpression'] = projectionExpression;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * This method returns a Satellite Telemetry Entry by ID for the current user
         * @summary Get a Satellite Telemetry Entry by ID
         * @param {string} telemetryEntryId ID of the Satellite Telemetry Entry to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTelemetryEntryById: function (telemetryEntryId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'telemetryEntryId' is not null or undefined
                            common_1.assertParamExists('getTelemetryEntryById', 'telemetryEntryId', telemetryEntryId);
                            localVarPath = "/satellite-telemetry/{telemetryEntryId}"
                                .replace("{" + "telemetryEntryId" + "}", encodeURIComponent(String(telemetryEntryId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication MyEnduroSat required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)];
                        case 1:
                            // authentication MyEnduroSat required
                            // oauth required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * This method is used for recording batch telemetry for a satellite.
         * @summary Record Batch Telemetry
         * @param {Array<TelemetryEntry>} telemetryEntry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recordBatchTelemetry: function (telemetryEntry, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'telemetryEntry' is not null or undefined
                            common_1.assertParamExists('recordBatchTelemetry', 'telemetryEntry', telemetryEntry);
                            localVarPath = "/satellite-telemetry/batch";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication MyEnduroSat required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)];
                        case 1:
                            // authentication MyEnduroSat required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(telemetryEntry, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * This method is used for recording telemetry with a payload for a satellite.
         * @summary Record Telemetry with Payload
         * @param {any} payload
         * @param {TelemetryEntry} telemetry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recordTelemetryWithPayload: function (payload, telemetry, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'payload' is not null or undefined
                            common_1.assertParamExists('recordTelemetryWithPayload', 'payload', payload);
                            // verify required parameter 'telemetry' is not null or undefined
                            common_1.assertParamExists('recordTelemetryWithPayload', 'telemetry', telemetry);
                            localVarPath = "/satellite-telemetry/with-payload";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
                            // authentication MyEnduroSat required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)];
                        case 1:
                            // authentication MyEnduroSat required
                            // oauth required
                            _a.sent();
                            if (payload !== undefined) {
                                localVarFormParams.append('payload', payload);
                            }
                            if (telemetry !== undefined) {
                                localVarFormParams.append('telemetry', new Blob([JSON.stringify(telemetry)], { type: "application/json", }));
                            }
                            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = localVarFormParams;
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * This method updates a Telemetry Entry by ID
         * @summary Update a Telemetry Entry
         * @param {string} telemetryEntryId ID of the Telemetry Entry to update
         * @param {TelemetryEntry} telemetryEntry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTelemetryEntryById: function (telemetryEntryId, telemetryEntry, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'telemetryEntryId' is not null or undefined
                            common_1.assertParamExists('updateTelemetryEntryById', 'telemetryEntryId', telemetryEntryId);
                            // verify required parameter 'telemetryEntry' is not null or undefined
                            common_1.assertParamExists('updateTelemetryEntryById', 'telemetryEntry', telemetryEntry);
                            localVarPath = "/satellite-telemetry/{telemetryEntryId}"
                                .replace("{" + "telemetryEntryId" + "}", encodeURIComponent(String(telemetryEntryId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication MyEnduroSat required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)];
                        case 1:
                            // authentication MyEnduroSat required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(telemetryEntry, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
/**
 * SatelliteTelemetryApi - functional programming interface
 * @export
 */
exports.SatelliteTelemetryApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.SatelliteTelemetryApiAxiosParamCreator(configuration);
    return {
        /**
         * This method returns a filtered list of historical telemetry values for a satellite. Telemetry could be filtered by Satellite, Satellite Pass and time received
         * @summary Get Historical Telemetry
         * @param {string} [name] Specifies the name (or part of the name) of the telemetry
         * @param {string} [satelliteId] Specifies the satellite for which to get telemetry
         * @param {string} [satelliteSubsystemId] Specifies the satellite subsystem (or part of the subsytem) for which to get telemetry
         * @param {string} [passId] Specifies the satellite pass (or part of the pass) during which telemetry was aquired
         * @param {number} [fromTime] Filter to only return entries that were received after the specified time (inclusive)
         * @param {number} [toTime] Filter to only return entries that were received before the specified time (exclusive)
         * @param {string} [missionDatabase] Filter by mission database (or part of the mission database)
         * @param {string} [telecommandRequest] Filter by telecommand request
         * @param {string} [lastEvaluatedItem] Specified the ID of the last item that was returned from the last page
         * @param {number} [pageSizeLimit] Specifies the maximum number of results per page. Defaults to 100
         * @param {string} [dataQuery] Allows you to filter telemetry data using a query that supports the following comparison operations: \&#39;&gt;\&#39; (greater than), \&#39;&#x3D;&#x3D;\&#39;(equal to), \&#39;&lt;\&#39;(less than), \&#39;&gt;&#x3D;\&#39; (greater than or equal to), \&#39;&lt;&#x3D;\&#39; (less than or equal to). Supported logical operators are \&#39;and\&#39; and \&#39;or\&#39;. Fields and values are case-sensitive. Example: fpHeader.SatId &#x3D;&#x3D; 1 and (fpHeader.FuncId &#x3D;&#x3D; 2 or fpHeader.FuncId &#x3D;&#x3D; 3)
         * @param {string} [projectionExpression] Allows you to specify a subset of attributes to be returned. By default, all attributes are returned. Projection expression supports all properties of the Telemetry. Example: fpHeader.SatId, fpHeader.FuncId, data.types[0]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilteredTelemetryEntries: function (name, satelliteId, satelliteSubsystemId, passId, fromTime, toTime, missionDatabase, telecommandRequest, lastEvaluatedItem, pageSizeLimit, dataQuery, projectionExpression, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getFilteredTelemetryEntries(name, satelliteId, satelliteSubsystemId, passId, fromTime, toTime, missionDatabase, telecommandRequest, lastEvaluatedItem, pageSizeLimit, dataQuery, projectionExpression, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * This method returns a Satellite Telemetry Entry by ID for the current user
         * @summary Get a Satellite Telemetry Entry by ID
         * @param {string} telemetryEntryId ID of the Satellite Telemetry Entry to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTelemetryEntryById: function (telemetryEntryId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getTelemetryEntryById(telemetryEntryId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * This method is used for recording batch telemetry for a satellite.
         * @summary Record Batch Telemetry
         * @param {Array<TelemetryEntry>} telemetryEntry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recordBatchTelemetry: function (telemetryEntry, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.recordBatchTelemetry(telemetryEntry, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * This method is used for recording telemetry with a payload for a satellite.
         * @summary Record Telemetry with Payload
         * @param {any} payload
         * @param {TelemetryEntry} telemetry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recordTelemetryWithPayload: function (payload, telemetry, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.recordTelemetryWithPayload(payload, telemetry, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * This method updates a Telemetry Entry by ID
         * @summary Update a Telemetry Entry
         * @param {string} telemetryEntryId ID of the Telemetry Entry to update
         * @param {TelemetryEntry} telemetryEntry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTelemetryEntryById: function (telemetryEntryId, telemetryEntry, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateTelemetryEntryById(telemetryEntryId, telemetryEntry, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * SatelliteTelemetryApi - factory interface
 * @export
 */
exports.SatelliteTelemetryApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.SatelliteTelemetryApiFp(configuration);
    return {
        /**
         * This method returns a filtered list of historical telemetry values for a satellite. Telemetry could be filtered by Satellite, Satellite Pass and time received
         * @summary Get Historical Telemetry
         * @param {string} [name] Specifies the name (or part of the name) of the telemetry
         * @param {string} [satelliteId] Specifies the satellite for which to get telemetry
         * @param {string} [satelliteSubsystemId] Specifies the satellite subsystem (or part of the subsytem) for which to get telemetry
         * @param {string} [passId] Specifies the satellite pass (or part of the pass) during which telemetry was aquired
         * @param {number} [fromTime] Filter to only return entries that were received after the specified time (inclusive)
         * @param {number} [toTime] Filter to only return entries that were received before the specified time (exclusive)
         * @param {string} [missionDatabase] Filter by mission database (or part of the mission database)
         * @param {string} [telecommandRequest] Filter by telecommand request
         * @param {string} [lastEvaluatedItem] Specified the ID of the last item that was returned from the last page
         * @param {number} [pageSizeLimit] Specifies the maximum number of results per page. Defaults to 100
         * @param {string} [dataQuery] Allows you to filter telemetry data using a query that supports the following comparison operations: \&#39;&gt;\&#39; (greater than), \&#39;&#x3D;&#x3D;\&#39;(equal to), \&#39;&lt;\&#39;(less than), \&#39;&gt;&#x3D;\&#39; (greater than or equal to), \&#39;&lt;&#x3D;\&#39; (less than or equal to). Supported logical operators are \&#39;and\&#39; and \&#39;or\&#39;. Fields and values are case-sensitive. Example: fpHeader.SatId &#x3D;&#x3D; 1 and (fpHeader.FuncId &#x3D;&#x3D; 2 or fpHeader.FuncId &#x3D;&#x3D; 3)
         * @param {string} [projectionExpression] Allows you to specify a subset of attributes to be returned. By default, all attributes are returned. Projection expression supports all properties of the Telemetry. Example: fpHeader.SatId, fpHeader.FuncId, data.types[0]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilteredTelemetryEntries: function (name, satelliteId, satelliteSubsystemId, passId, fromTime, toTime, missionDatabase, telecommandRequest, lastEvaluatedItem, pageSizeLimit, dataQuery, projectionExpression, options) {
            return localVarFp.getFilteredTelemetryEntries(name, satelliteId, satelliteSubsystemId, passId, fromTime, toTime, missionDatabase, telecommandRequest, lastEvaluatedItem, pageSizeLimit, dataQuery, projectionExpression, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * This method returns a Satellite Telemetry Entry by ID for the current user
         * @summary Get a Satellite Telemetry Entry by ID
         * @param {string} telemetryEntryId ID of the Satellite Telemetry Entry to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTelemetryEntryById: function (telemetryEntryId, options) {
            return localVarFp.getTelemetryEntryById(telemetryEntryId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * This method is used for recording batch telemetry for a satellite.
         * @summary Record Batch Telemetry
         * @param {Array<TelemetryEntry>} telemetryEntry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recordBatchTelemetry: function (telemetryEntry, options) {
            return localVarFp.recordBatchTelemetry(telemetryEntry, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * This method is used for recording telemetry with a payload for a satellite.
         * @summary Record Telemetry with Payload
         * @param {any} payload
         * @param {TelemetryEntry} telemetry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recordTelemetryWithPayload: function (payload, telemetry, options) {
            return localVarFp.recordTelemetryWithPayload(payload, telemetry, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * This method updates a Telemetry Entry by ID
         * @summary Update a Telemetry Entry
         * @param {string} telemetryEntryId ID of the Telemetry Entry to update
         * @param {TelemetryEntry} telemetryEntry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTelemetryEntryById: function (telemetryEntryId, telemetryEntry, options) {
            return localVarFp.updateTelemetryEntryById(telemetryEntryId, telemetryEntry, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * SatelliteTelemetryApi - object-oriented interface
 * @export
 * @class SatelliteTelemetryApi
 * @extends {BaseAPI}
 */
var SatelliteTelemetryApi = /** @class */ (function (_super) {
    __extends(SatelliteTelemetryApi, _super);
    function SatelliteTelemetryApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * This method returns a filtered list of historical telemetry values for a satellite. Telemetry could be filtered by Satellite, Satellite Pass and time received
     * @summary Get Historical Telemetry
     * @param {string} [name] Specifies the name (or part of the name) of the telemetry
     * @param {string} [satelliteId] Specifies the satellite for which to get telemetry
     * @param {string} [satelliteSubsystemId] Specifies the satellite subsystem (or part of the subsytem) for which to get telemetry
     * @param {string} [passId] Specifies the satellite pass (or part of the pass) during which telemetry was aquired
     * @param {number} [fromTime] Filter to only return entries that were received after the specified time (inclusive)
     * @param {number} [toTime] Filter to only return entries that were received before the specified time (exclusive)
     * @param {string} [missionDatabase] Filter by mission database (or part of the mission database)
     * @param {string} [telecommandRequest] Filter by telecommand request
     * @param {string} [lastEvaluatedItem] Specified the ID of the last item that was returned from the last page
     * @param {number} [pageSizeLimit] Specifies the maximum number of results per page. Defaults to 100
     * @param {string} [dataQuery] Allows you to filter telemetry data using a query that supports the following comparison operations: \&#39;&gt;\&#39; (greater than), \&#39;&#x3D;&#x3D;\&#39;(equal to), \&#39;&lt;\&#39;(less than), \&#39;&gt;&#x3D;\&#39; (greater than or equal to), \&#39;&lt;&#x3D;\&#39; (less than or equal to). Supported logical operators are \&#39;and\&#39; and \&#39;or\&#39;. Fields and values are case-sensitive. Example: fpHeader.SatId &#x3D;&#x3D; 1 and (fpHeader.FuncId &#x3D;&#x3D; 2 or fpHeader.FuncId &#x3D;&#x3D; 3)
     * @param {string} [projectionExpression] Allows you to specify a subset of attributes to be returned. By default, all attributes are returned. Projection expression supports all properties of the Telemetry. Example: fpHeader.SatId, fpHeader.FuncId, data.types[0]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SatelliteTelemetryApi
     */
    SatelliteTelemetryApi.prototype.getFilteredTelemetryEntries = function (name, satelliteId, satelliteSubsystemId, passId, fromTime, toTime, missionDatabase, telecommandRequest, lastEvaluatedItem, pageSizeLimit, dataQuery, projectionExpression, options) {
        var _this = this;
        return exports.SatelliteTelemetryApiFp(this.configuration).getFilteredTelemetryEntries(name, satelliteId, satelliteSubsystemId, passId, fromTime, toTime, missionDatabase, telecommandRequest, lastEvaluatedItem, pageSizeLimit, dataQuery, projectionExpression, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * This method returns a Satellite Telemetry Entry by ID for the current user
     * @summary Get a Satellite Telemetry Entry by ID
     * @param {string} telemetryEntryId ID of the Satellite Telemetry Entry to get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SatelliteTelemetryApi
     */
    SatelliteTelemetryApi.prototype.getTelemetryEntryById = function (telemetryEntryId, options) {
        var _this = this;
        return exports.SatelliteTelemetryApiFp(this.configuration).getTelemetryEntryById(telemetryEntryId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * This method is used for recording batch telemetry for a satellite.
     * @summary Record Batch Telemetry
     * @param {Array<TelemetryEntry>} telemetryEntry
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SatelliteTelemetryApi
     */
    SatelliteTelemetryApi.prototype.recordBatchTelemetry = function (telemetryEntry, options) {
        var _this = this;
        return exports.SatelliteTelemetryApiFp(this.configuration).recordBatchTelemetry(telemetryEntry, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * This method is used for recording telemetry with a payload for a satellite.
     * @summary Record Telemetry with Payload
     * @param {any} payload
     * @param {TelemetryEntry} telemetry
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SatelliteTelemetryApi
     */
    SatelliteTelemetryApi.prototype.recordTelemetryWithPayload = function (payload, telemetry, options) {
        var _this = this;
        return exports.SatelliteTelemetryApiFp(this.configuration).recordTelemetryWithPayload(payload, telemetry, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * This method updates a Telemetry Entry by ID
     * @summary Update a Telemetry Entry
     * @param {string} telemetryEntryId ID of the Telemetry Entry to update
     * @param {TelemetryEntry} telemetryEntry
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SatelliteTelemetryApi
     */
    SatelliteTelemetryApi.prototype.updateTelemetryEntryById = function (telemetryEntryId, telemetryEntry, options) {
        var _this = this;
        return exports.SatelliteTelemetryApiFp(this.configuration).updateTelemetryEntryById(telemetryEntryId, telemetryEntry, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return SatelliteTelemetryApi;
}(base_1.BaseAPI));
exports.SatelliteTelemetryApi = SatelliteTelemetryApi;
/**
 * SatellitesApi - axios parameter creator
 * @export
 */
exports.SatellitesApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * This method creates a new satellite for the current user.
         * @summary Create a Satellite
         * @param {Satellite} satellite
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSatellite: function (satellite, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'satellite' is not null or undefined
                            common_1.assertParamExists('createSatellite', 'satellite', satellite);
                            localVarPath = "/satellites";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication MyEnduroSat required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)];
                        case 1:
                            // authentication MyEnduroSat required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(satellite, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * This method deletes a Satellite by ID
         * @summary Delete a Satellite
         * @param {string} satelliteId ID of the Satellite to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSatelliteById: function (satelliteId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'satelliteId' is not null or undefined
                            common_1.assertParamExists('deleteSatelliteById', 'satelliteId', satelliteId);
                            localVarPath = "/satellites/{satelliteId}"
                                .replace("{" + "satelliteId" + "}", encodeURIComponent(String(satelliteId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication MyEnduroSat required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)];
                        case 1:
                            // authentication MyEnduroSat required
                            // oauth required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * This method returns a list of all Satellites available for the user
         * @summary Get all Satellites
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSatellites: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/satellites";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication MyEnduroSat required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)];
                        case 1:
                            // authentication MyEnduroSat required
                            // oauth required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * This method returns a Satellite by ID
         * @summary Get a Satellite by ID
         * @param {string} satelliteId ID of the Satellite to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSatelliteById: function (satelliteId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'satelliteId' is not null or undefined
                            common_1.assertParamExists('getSatelliteById', 'satelliteId', satelliteId);
                            localVarPath = "/satellites/{satelliteId}"
                                .replace("{" + "satelliteId" + "}", encodeURIComponent(String(satelliteId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication MyEnduroSat required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)];
                        case 1:
                            // authentication MyEnduroSat required
                            // oauth required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * This method updates a Satellite by ID
         * @summary Update a Satellite
         * @param {string} satelliteId ID of the Satellite to update
         * @param {Satellite} satellite
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSatelliteById: function (satelliteId, satellite, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'satelliteId' is not null or undefined
                            common_1.assertParamExists('updateSatelliteById', 'satelliteId', satelliteId);
                            // verify required parameter 'satellite' is not null or undefined
                            common_1.assertParamExists('updateSatelliteById', 'satellite', satellite);
                            localVarPath = "/satellites/{satelliteId}"
                                .replace("{" + "satelliteId" + "}", encodeURIComponent(String(satelliteId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication MyEnduroSat required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)];
                        case 1:
                            // authentication MyEnduroSat required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(satellite, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
/**
 * SatellitesApi - functional programming interface
 * @export
 */
exports.SatellitesApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.SatellitesApiAxiosParamCreator(configuration);
    return {
        /**
         * This method creates a new satellite for the current user.
         * @summary Create a Satellite
         * @param {Satellite} satellite
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSatellite: function (satellite, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createSatellite(satellite, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * This method deletes a Satellite by ID
         * @summary Delete a Satellite
         * @param {string} satelliteId ID of the Satellite to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSatelliteById: function (satelliteId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteSatelliteById(satelliteId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * This method returns a list of all Satellites available for the user
         * @summary Get all Satellites
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSatellites: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getAllSatellites(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * This method returns a Satellite by ID
         * @summary Get a Satellite by ID
         * @param {string} satelliteId ID of the Satellite to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSatelliteById: function (satelliteId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getSatelliteById(satelliteId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * This method updates a Satellite by ID
         * @summary Update a Satellite
         * @param {string} satelliteId ID of the Satellite to update
         * @param {Satellite} satellite
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSatelliteById: function (satelliteId, satellite, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateSatelliteById(satelliteId, satellite, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * SatellitesApi - factory interface
 * @export
 */
exports.SatellitesApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.SatellitesApiFp(configuration);
    return {
        /**
         * This method creates a new satellite for the current user.
         * @summary Create a Satellite
         * @param {Satellite} satellite
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSatellite: function (satellite, options) {
            return localVarFp.createSatellite(satellite, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * This method deletes a Satellite by ID
         * @summary Delete a Satellite
         * @param {string} satelliteId ID of the Satellite to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSatelliteById: function (satelliteId, options) {
            return localVarFp.deleteSatelliteById(satelliteId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * This method returns a list of all Satellites available for the user
         * @summary Get all Satellites
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSatellites: function (options) {
            return localVarFp.getAllSatellites(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * This method returns a Satellite by ID
         * @summary Get a Satellite by ID
         * @param {string} satelliteId ID of the Satellite to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSatelliteById: function (satelliteId, options) {
            return localVarFp.getSatelliteById(satelliteId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * This method updates a Satellite by ID
         * @summary Update a Satellite
         * @param {string} satelliteId ID of the Satellite to update
         * @param {Satellite} satellite
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSatelliteById: function (satelliteId, satellite, options) {
            return localVarFp.updateSatelliteById(satelliteId, satellite, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * SatellitesApi - object-oriented interface
 * @export
 * @class SatellitesApi
 * @extends {BaseAPI}
 */
var SatellitesApi = /** @class */ (function (_super) {
    __extends(SatellitesApi, _super);
    function SatellitesApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * This method creates a new satellite for the current user.
     * @summary Create a Satellite
     * @param {Satellite} satellite
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SatellitesApi
     */
    SatellitesApi.prototype.createSatellite = function (satellite, options) {
        var _this = this;
        return exports.SatellitesApiFp(this.configuration).createSatellite(satellite, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * This method deletes a Satellite by ID
     * @summary Delete a Satellite
     * @param {string} satelliteId ID of the Satellite to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SatellitesApi
     */
    SatellitesApi.prototype.deleteSatelliteById = function (satelliteId, options) {
        var _this = this;
        return exports.SatellitesApiFp(this.configuration).deleteSatelliteById(satelliteId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * This method returns a list of all Satellites available for the user
     * @summary Get all Satellites
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SatellitesApi
     */
    SatellitesApi.prototype.getAllSatellites = function (options) {
        var _this = this;
        return exports.SatellitesApiFp(this.configuration).getAllSatellites(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * This method returns a Satellite by ID
     * @summary Get a Satellite by ID
     * @param {string} satelliteId ID of the Satellite to get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SatellitesApi
     */
    SatellitesApi.prototype.getSatelliteById = function (satelliteId, options) {
        var _this = this;
        return exports.SatellitesApiFp(this.configuration).getSatelliteById(satelliteId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * This method updates a Satellite by ID
     * @summary Update a Satellite
     * @param {string} satelliteId ID of the Satellite to update
     * @param {Satellite} satellite
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SatellitesApi
     */
    SatellitesApi.prototype.updateSatelliteById = function (satelliteId, satellite, options) {
        var _this = this;
        return exports.SatellitesApiFp(this.configuration).updateSatelliteById(satelliteId, satellite, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return SatellitesApi;
}(base_1.BaseAPI));
exports.SatellitesApi = SatellitesApi;
/**
 * UserDataApi - axios parameter creator
 * @export
 */
exports.UserDataApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * This method deletes user data connected to a key
         * @summary Delete User Data
         * @param {string} userDataId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserDataWithKey: function (userDataId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'userDataId' is not null or undefined
                            common_1.assertParamExists('deleteUserDataWithKey', 'userDataId', userDataId);
                            localVarPath = "/user-data/{userDataId}"
                                .replace("{" + "userDataId" + "}", encodeURIComponent(String(userDataId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication MyEnduroSat required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)];
                        case 1:
                            // authentication MyEnduroSat required
                            // oauth required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * This method changes an existing record with a key
         * @summary Edit User Data
         * @param {string} userDataId
         * @param {UserDataRecord} userDataRecord
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUserDataWithKey: function (userDataId, userDataRecord, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'userDataId' is not null or undefined
                            common_1.assertParamExists('editUserDataWithKey', 'userDataId', userDataId);
                            // verify required parameter 'userDataRecord' is not null or undefined
                            common_1.assertParamExists('editUserDataWithKey', 'userDataRecord', userDataRecord);
                            localVarPath = "/user-data/{userDataId}"
                                .replace("{" + "userDataId" + "}", encodeURIComponent(String(userDataId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication MyEnduroSat required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)];
                        case 1:
                            // authentication MyEnduroSat required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(userDataRecord, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * This method returns User Data from a key
         * @summary Get User Data
         * @param {string} userDataId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDataByKey: function (userDataId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'userDataId' is not null or undefined
                            common_1.assertParamExists('getUserDataByKey', 'userDataId', userDataId);
                            localVarPath = "/user-data/{userDataId}"
                                .replace("{" + "userDataId" + "}", encodeURIComponent(String(userDataId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication MyEnduroSat required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)];
                        case 1:
                            // authentication MyEnduroSat required
                            // oauth required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
/**
 * UserDataApi - functional programming interface
 * @export
 */
exports.UserDataApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.UserDataApiAxiosParamCreator(configuration);
    return {
        /**
         * This method deletes user data connected to a key
         * @summary Delete User Data
         * @param {string} userDataId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserDataWithKey: function (userDataId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteUserDataWithKey(userDataId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * This method changes an existing record with a key
         * @summary Edit User Data
         * @param {string} userDataId
         * @param {UserDataRecord} userDataRecord
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUserDataWithKey: function (userDataId, userDataRecord, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.editUserDataWithKey(userDataId, userDataRecord, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * This method returns User Data from a key
         * @summary Get User Data
         * @param {string} userDataId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDataByKey: function (userDataId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getUserDataByKey(userDataId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * UserDataApi - factory interface
 * @export
 */
exports.UserDataApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.UserDataApiFp(configuration);
    return {
        /**
         * This method deletes user data connected to a key
         * @summary Delete User Data
         * @param {string} userDataId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserDataWithKey: function (userDataId, options) {
            return localVarFp.deleteUserDataWithKey(userDataId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * This method changes an existing record with a key
         * @summary Edit User Data
         * @param {string} userDataId
         * @param {UserDataRecord} userDataRecord
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUserDataWithKey: function (userDataId, userDataRecord, options) {
            return localVarFp.editUserDataWithKey(userDataId, userDataRecord, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * This method returns User Data from a key
         * @summary Get User Data
         * @param {string} userDataId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDataByKey: function (userDataId, options) {
            return localVarFp.getUserDataByKey(userDataId, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * UserDataApi - object-oriented interface
 * @export
 * @class UserDataApi
 * @extends {BaseAPI}
 */
var UserDataApi = /** @class */ (function (_super) {
    __extends(UserDataApi, _super);
    function UserDataApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * This method deletes user data connected to a key
     * @summary Delete User Data
     * @param {string} userDataId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserDataApi
     */
    UserDataApi.prototype.deleteUserDataWithKey = function (userDataId, options) {
        var _this = this;
        return exports.UserDataApiFp(this.configuration).deleteUserDataWithKey(userDataId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * This method changes an existing record with a key
     * @summary Edit User Data
     * @param {string} userDataId
     * @param {UserDataRecord} userDataRecord
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserDataApi
     */
    UserDataApi.prototype.editUserDataWithKey = function (userDataId, userDataRecord, options) {
        var _this = this;
        return exports.UserDataApiFp(this.configuration).editUserDataWithKey(userDataId, userDataRecord, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * This method returns User Data from a key
     * @summary Get User Data
     * @param {string} userDataId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserDataApi
     */
    UserDataApi.prototype.getUserDataByKey = function (userDataId, options) {
        var _this = this;
        return exports.UserDataApiFp(this.configuration).getUserDataByKey(userDataId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return UserDataApi;
}(base_1.BaseAPI));
exports.UserDataApi = UserDataApi;
