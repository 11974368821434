import {createAsyncThunk} from "@reduxjs/toolkit";
import {Accessory, Module} from "models";
import {DynamoDBService} from "services/dynamo-db.service";
import {Tables} from "utils/ddbTables";

export const addNewModule = createAsyncThunk(
    'add/module',
    async (module: Module) => {
        await DynamoDBService.putItem(Tables.MODULES, module)
        return await DynamoDBService.getItemsInTable(Tables.MODULES)
    }
)

export const updateModule = createAsyncThunk(
    'update/module',
    async (module: Module) => {
        await DynamoDBService.update(Tables.MODULES, module)
        return await DynamoDBService.getItemsInTable(Tables.MODULES)
    }
)

export const deleteModule = createAsyncThunk(
    'delete/module',
    async (id: string) => {
        await DynamoDBService.delete(Tables.MODULES, id)
        return await DynamoDBService.getItemsInTable(Tables.MODULES)
    }
)

export const fetchModules = createAsyncThunk(
    'fetch/module',
    async () => await DynamoDBService.getItemsInTable(Tables.MODULES)
)

export const addNewAccessory = createAsyncThunk(
    'add/accessory',
    async (accessory: Accessory) => {
        await DynamoDBService.putItem(Tables.ACCESSORIES, accessory)
        return await DynamoDBService.getItemsInTable(Tables.ACCESSORIES)
    }
)

export const fetchAccessories = createAsyncThunk(
    'fetch/accessory',
    async () => DynamoDBService.getItemsInTable(Tables.ACCESSORIES)
)

export const deleteAccessory = createAsyncThunk(
    'delete/accessory',
    async (id: string) => {
        await DynamoDBService.delete(Tables.ACCESSORIES, id)
        return await DynamoDBService.getItemsInTable(Tables.ACCESSORIES)
    }
)

export const updateAccessory = createAsyncThunk(
    'update/accessory',
    async (accessory: Accessory) => {
        await DynamoDBService.update(Tables.ACCESSORIES, accessory)
        return await DynamoDBService.getItemsInTable(Tables.ACCESSORIES)
    }
)
