import React, {FC, CSSProperties, ReactNode, useState, useEffect} from "react";
import styles from './styles.module.scss'
import * as colors from '@endurosat/colors'
import {BiChevronRight} from "react-icons/bi";

interface Props {
    title?: string | ReactNode;
    icon?: ReactNode;

    style?: CSSProperties;
    headerStyle?: CSSProperties;
    className?: string;
    children?: ReactNode;
    bordered?: boolean;
    headerRight?: ReactNode;
}

const Table: FC<Props> = ({
    children,
    title,
    icon,
    style = {},
    className = '',
    bordered = true,
    headerRight,
    headerStyle = {}
}) => {

    const border = bordered ? `1px solid ${colors.BORDER}` : 'none'

    return (
        <div className={`${styles.container} ${className}`} style={{...style, border}}>
            {(icon || title) && (
                <div className={styles.header} style={headerStyle}>
                    <span className={styles.headerInner}>{icon}<span>{title}</span></span>{headerRight}
                </div>
            )}

            <div className={styles.list}>
                {children}
            </div>
        </div>
    )
}

interface TableRowProps {
    style?: CSSProperties;
    className?: string;
    onClick?: () => void;
    icon?: ReactNode;
    text?: string | ReactNode;
    children?: ReactNode;
}

export const TableRow: FC<TableRowProps> = ({
    style,
    className = '',
    onClick,
    icon,
    text,
    children
}) => {
    return (
        <span className={`${styles.listItem} ${className}`} style={style} onClick={onClick}>
            {text && <span className={styles.listItemLabel}>{text}</span>}
            {icon}
            {children}
        </span>
    )
}

interface TableSectionProps {
    title?: string;
    style?: CSSProperties;
    className?: string;
    children?: ReactNode;
    defaultExpand?: boolean;
}

export const TableSection:FC<TableSectionProps> = ({title, style = {}, className = '', children, defaultExpand = true}) => {
    const [expanded, setExpanded] = useState(true);

    useEffect(() => {
        setExpanded(defaultExpand)
    }, [])

    return (
        <div className={className} style={style}>
            <div className={styles.sectionHeader} onClick={() => setExpanded(!expanded)}>
                <span className={expanded ? styles.expandedText : styles.notExpandedText}>{title}</span>
                <BiChevronRight className={expanded ? styles.expandedSvg : styles.notExpandedSvg}/>
            </div>
            {expanded && children}
        </div>
    )
}

export default Table;