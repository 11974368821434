import * as React from 'react';
import styles from './styles.module.scss'
import {Button} from "components";
import {SiMicrosoft} from "react-icons/si";
import {GoPackage} from "react-icons/go";

import EnduroSatLogo from 'assets/brand/logo.png'
import authenticationService from "services/authentication.service";
const Login = () => {

    const login = () => {
        window.location.replace(authenticationService.getAuthURL())
    }

    return (
        <div className={styles.page}>
            <img alt='company logo' src={EnduroSatLogo} />
            <div className={styles.content}>
                <GoPackage />
                <span className={styles.title}>Hi there, welcome to the EnduroSat order assistant!</span>
                <Button type='primary' label='Log in to continue' icon={<SiMicrosoft />} onClick={login}/>
            </div>
        </div>
    );
};

export default Login;