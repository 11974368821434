import React, {FC, CSSProperties, ReactNode} from "react";
import styles from './styles.module.scss'
interface Props {
    label: string;
    onClick?: () => void;
    style?: CSSProperties;
    className?: string;
    type?: 'primary' | 'primary-outlined' | 'danger' | 'danger-outlined' | 'text' | 'danger-text'
    icon?: ReactNode;
}

const Button: FC<Props> = ({
    label,
    onClick,
    style = {},
    className = '',
    type = 'primary',
    icon = <></>
}) => {
    return (
        <button className={`${styles.common} ${styles[type]} ${className}`} style={style} onClick={onClick}>
            {icon}<span className={styles.label}>{label}</span>
        </button>
    )
}

export default Button;