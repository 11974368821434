import {createSlice, current} from '@reduxjs/toolkit'
interface State {
    isOpen: boolean,
    text: string,
    type: 'info' | 'error' | 'success'
}

const initialState: State = {
    isOpen: false,
    text: 'Hello world!',
    type: 'info'
};

export const messageSlice = createSlice({
    name: 'message',
    initialState,

    reducers: {
        setMessage: (state: State, action) => {
            state.isOpen = true;
            state.text = action.payload.text;
            state.type = action.payload.type ? action.payload.type : 'info';
        },
        closeMessage: (state: State) => {
            state.isOpen = false;
        },
    },

})

export const {setMessage, closeMessage} = messageSlice.actions;

export default messageSlice.reducer