import React, {FC, CSSProperties} from "react";
import styles from './styles.module.scss'

interface Props {
    label: string;
    onClick?: () => void;

    style?: CSSProperties;
    className?: string;
}

const Tag: FC<Props> = ({label, onClick, style = {}, className = ''}) => {
    return (
        <span className={`${styles.tag} ${className}`} style={style} onClick={onClick}>
            <span>{label}</span>
        </span>
    )
}

export default Tag;