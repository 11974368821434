import {DeleteCommand, GetCommand, PutCommand, ScanCommand, BatchGetCommand} from "@aws-sdk/lib-dynamodb";
import {DynamoDBClient} from "@aws-sdk/client-dynamodb";
import {DynamoDBDocumentClient} from "@aws-sdk/lib-dynamodb";
import {AuthLocalStorage} from "./local-storage";
import envVariablesGetter from "utils/env-variables-getter";

const translateConfig = {
    marshallOptions: {
        convertEmptyValues: false,
        removeUndefinedValues: true,
        convertClassInstanceToMap: false,
    },
    unmarshallOptions: {
        wrapNumbers: false,
    }
}

class DynamoSBService {
    private client: any;
    initialize = () => {
        const region = envVariablesGetter.awsRegion;

        const {AccessKeyId: accessKeyId,
            SecretKey: secretAccessKey,
            SessionToken: sessionToken
        } = AuthLocalStorage.getCognitoCredentials();

        const documentClient = new DynamoDBClient({region, credentials: {accessKeyId, secretAccessKey, sessionToken}});
        this.client = DynamoDBDocumentClient.from(documentClient, translateConfig);
    }


    async sendCommand(cmd: any) {
        try {
            return await this.client.send(cmd) as any;
        } catch (e: any) {
            return console.log(e.message)
        }
    }

    async getItemsInTable(TableName: string) {
        const res = await this.sendCommand(new ScanCommand({TableName}))

        if(res && res?.Items) return res.Items
        return []
    }

    async getItemById(TableName: string, id: string) {
        const res = await this.sendCommand(new GetCommand({TableName, Key: {id}}))
        if(res && res?.Item) return res.Item
        return null
    }

    async getManyItemsByIds(TableName: string, ids: string[]) {
        let queryParams: any = {RequestItems: {}};
        queryParams.RequestItems[TableName] = {Keys: ids.map(id => ({id}))};
        const res = await this.sendCommand(new BatchGetCommand(queryParams))
        if(res && res?.Responses) return res.Responses
        return []
    }

    async putItem(TableName: string, item: any) {
        const Item = {...item, createDate: new Date().toISOString()}
        await this.sendCommand(new PutCommand({TableName, Item}))
    }

    async update(TableName: string, Item: any) {
        await this.sendCommand(new PutCommand({TableName, Item}))
    }

    async delete(TableName: string, id: string) {
        await this.sendCommand(new DeleteCommand({TableName, Key: {id}}))
    }
}

export const DynamoDBService = new DynamoSBService();