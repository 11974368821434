import {createSlice} from '@reduxjs/toolkit'

interface State {
    user: null | { username: string, email: string };
}

const initialState: State = {
    user: null
};

export const commonSlice = createSlice({
    name: 'common',
    initialState,

    reducers: {
        setUser: (state: State, action) => {
            state.user = action.payload;
        },
    },

})

// Action creators are generated for each case reducer function
export const {setUser} = commonSlice.actions

export default commonSlice.reducer
