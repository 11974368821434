//@ts-ignore
import Localbase from 'localbase'
import { isArray } from 'lodash'
import localStorageService from 'services/local-storage/local-storage.service'
import EnvVariablesGetterUtility from './env-variables-getter'

let cacheDB = new Localbase('api-cache')
cacheDB.config.debug = false

export const clearBrowserStorageKeysByMatch = async (matchString: string) => {
    // Checks if the browser supports a stable version of IndexedDB.
    if (window.indexedDB) {
        // Clearing the indexedDB
        await cacheDB
            .collection('keyvaluepairs')
            .get({ keys: true })
            .then((pairs: { key: string }[]) => {
                if (isArray(pairs)) {
                    pairs
                        .filter((pair: { key: string }) =>
                            pair.key.includes(matchString)
                        )
                        .forEach((pair: { key: string }) =>
                            cacheDB
                                .collection('keyvaluepairs')
                                .doc(pair.key)
                                .delete()
                        )
                }
            })

        return
    }

    // Clearing the localstorage
    localStorageService.removeItemsByMatch(matchString)
}

export const clearBrowserStorageApiCache = () => {
    // Checks if the browser supports a stable version of IndexedDB.
    if (window.indexedDB) {
        // Clearing the indexedDB
        cacheDB.collection('keyvaluepairs').delete()

        return
    }

    // Clearing the localstorage
    localStorageService.removeItemsByMatch(
        EnvVariablesGetterUtility.localForageApiCacheName
    )
}
