import { AuthenticationResponse } from 'gen/endurosat-api'
import { clearBrowserStorageApiCache } from 'utils/browser-storage-manipulations'
import {LocalStorage} from './'
class AuthLocalStorageService {
    setAuthData = (data: AuthenticationResponse) => {
        LocalStorage.setItem('authData', JSON.stringify(data))
    }

    getAuthData = (): AuthenticationResponse | undefined => {
        const authData = LocalStorage.getItem('authData')
        if (authData) {
            return JSON.parse(authData)
        }
    }

    removeAuthData = () => {
        LocalStorage.removeItem('authData')
        clearBrowserStorageApiCache()
    }

    setCognitoCredentials = (data: any) => {
        LocalStorage.setItem('cognitoCredentials', JSON.stringify(data))
    }

    getCognitoCredentials = () => {
        const cognitoCredentials = LocalStorage.getItem('cognitoCredentials')
        return cognitoCredentials ? JSON.parse(cognitoCredentials) : {}
    }

    removeCognitoCredentials = () => {
        LocalStorage.removeItem('cognitoCredentials')
        clearBrowserStorageApiCache()
    }

}

export default new AuthLocalStorageService()
