import React from 'react';
import {createPortal} from "react-dom";
import {useAppSelector} from "store/hooks";
import styles from "./styles.module.scss";
import {BsFillInfoCircleFill, BsCheckCircleFill} from "react-icons/bs";
import {RiCloseCircleFill} from "react-icons/ri";

const Message = () => {

    const {isOpen, text, type} = useAppSelector(state => state.messageHook)
    // if(!isOpen) return null;

    const Icon = () => (
        type === 'info' ? <BsFillInfoCircleFill className={styles.info}/>
            : type === 'error' ? <RiCloseCircleFill className={styles.error}/>
                : <BsCheckCircleFill className={styles.success}/>
    )
    return createPortal(
        <div className={`${styles.container} ${isOpen ? styles.visible : ''}`}>
            {<Icon/>}
            <span>{text}</span>
        </div>,
        document.body
    )
}


export default Message;