import * as React from 'react';
import {Routes, Route} from 'react-router-dom';
import {Suspense, useEffect} from "react";
import AuthService from "services/authentication.service";
import {AuthLocalStorage} from "services/local-storage";
import {useAppDispatch, useAppSelector} from "store/hooks";
import {setUser} from "store/commonSlice";
import authenticationService from "services/authentication.service";
import {DynamoDBService} from "services/dynamo-db.service";

const Login = React.lazy(() => import('pages/Login'));
const Logout = React.lazy(() => import('pages/Logout'));
const LoginRedirect = React.lazy(() => import('pages/Login/LoginRedirect'));
const Layout = React.lazy(() => import('Layout'));
const OrderBuilder = React.lazy(() => import('views/OrderBuilder'));
const Library = React.lazy(() => import('views/Library'));

const App = () => {
    const {user} = useAppSelector(state => state.common)

    const dispatch = useAppDispatch();

    const setupUser = async () => {
        authenticationService.getUserInfoFromAuthIdToken()
            .then((res) => {
                dispatch(setUser({
                    username: res.email.split('@')[0],
                    email: res.email
                }))
            })
    }

    const isUserAuthenticated = () => {
        const authData = AuthLocalStorage.getAuthData()
        const cognitoCredentials = AuthLocalStorage.getCognitoCredentials()
        const expired = new Date(cognitoCredentials.Expiration).getTime() < new Date().getTime();

        if (!authData || expired) {
            dispatch(setUser(null))
            return console.log('No valid authentication data in the storage')
        }
        DynamoDBService.initialize()

        if (AuthService.isAuthAccessTokenExpired(authData.access_token!)) {
            if (!authData.refresh_token) return console.log('No valid refresh token in the storage')

            AuthService.refreshTokens(authData.refresh_token).then(data => {
                AuthService.storeAuthTokens({...data, refresh_token: authData.refresh_token})
                setupUser()
            })
                .catch(e => dispatch(setUser(null)))
        } else {
            setupUser()
        }
    }

    useEffect(() => {
        isUserAuthenticated()
        window.addEventListener('storage', isUserAuthenticated)

        return function cleanup() {
            window.removeEventListener('storage', isUserAuthenticated)
        }
    }, [])

    return (
        <Suspense fallback={<span>Loading</span>}>
            {
                !!user
                    ? <Layout>
                        <Routes>
                            <Route index element={<OrderBuilder/>}/>
                            <Route path='/order-builder' element={<OrderBuilder/>}/>
                            <Route path='/library' element={<Library/>}/>
                            <Route path='/logout' element={<Logout/>}/>
                            <Route path='*' element={<OrderBuilder/>}/>
                        </Routes>
                    </Layout>

                    : <Routes>
                        <Route index element={<Login/>}/>
                        <Route path='/login' element={<Login/>}/>
                        <Route path='/login-redirect' element={<LoginRedirect/>}/>
                        <Route path='*' element={<Login/>}/>
                    </Routes>
            }
        </Suspense>
    )
};

export default App;