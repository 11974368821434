import React from 'react';
import {createPortal} from "react-dom";
import {useAppDispatch, useAppSelector} from "store/hooks";
import styles from "./styles.module.scss";
import {closeConfirmationModal} from "store/customHooks/confirmationModalSlice";
import {Button} from "components";

const ConfirmationModal = () => {
    const {
        isOpen,
        text,
        onConfirm
    } = useAppSelector(state => state.confirmationModal)
    const dispatch = useAppDispatch()

    if (!isOpen) return null;

    return createPortal(
        <div>
            <div className={styles.mask}/>
            <div className={styles.modal}>
                <p>{text}</p>
                <div className={styles.footer}>
                    <Button type='primary-outlined' onClick={() => dispatch(closeConfirmationModal())} label={'Cancel'}/>
                    <Button type='danger-outlined' onClick={onConfirm} label={'Confirm'}/>
                </div>
            </div>
        </div>,
        document.body
    )
}


export default ConfirmationModal;
