import {createSlice} from '@reduxjs/toolkit'
import {Accessory, Module} from "models";
import {
    addNewModule,
    updateModule,
    fetchModules,
    deleteModule,
    addNewAccessory,
    deleteAccessory,
    fetchAccessories,
    updateAccessory,
} from './reducers'

interface State {
    modules: Module[],
    accessories: Accessory[],
    status: 'idle' | 'loading' | 'failed'
}

const initialState: State = {
    modules: [],
    accessories: [],
    status: 'idle'
};

export const librarySlice = createSlice({
    name: 'library',
    initialState,

    reducers: {},
    extraReducers: builder => {

        // Modules
        builder.addCase(addNewModule.fulfilled, (state, action) => {
            state.modules = action.payload as Module[];
        })

        builder.addCase(fetchModules.pending, (state, action) => {
            state.status = 'loading';
        })
        builder.addCase(fetchModules.fulfilled, (state, action) => {
            state.modules = action.payload as Module[];
            state.status = 'idle';
        })
        builder.addCase(fetchModules.rejected, (state, action) => {
            state.modules = action.payload as Module[];
            state.status = 'failed';
        })

        builder.addCase(updateModule.fulfilled, (state, action) => {
            state.modules = action.payload as Module[];
        })
        builder.addCase(deleteModule.fulfilled, (state, action) => {
            state.modules = action.payload as Module[];
        })

        // Accessories
        builder.addCase(addNewAccessory.fulfilled, (state, action) => {
            state.accessories = action.payload as Accessory[];
        })

        builder.addCase(fetchAccessories.pending, (state, action) => {
            state.status = 'loading';
        })
        builder.addCase(fetchAccessories.fulfilled, (state, action) => {
            state.accessories = action.payload as Accessory[];
            state.status = 'idle';
        })
        builder.addCase(fetchAccessories.rejected, (state, action) => {
            state.status = 'failed';
        })

        builder.addCase(deleteAccessory.fulfilled, (state, action) => {
            state.accessories = action.payload as Accessory[];
        })
        builder.addCase(updateAccessory.fulfilled, (state, action) => {
            debugger
            state.accessories = action.payload as Accessory[];
        })
    },

})

export default librarySlice.reducer