import React, {FC, CSSProperties} from "react";
import styles from './styles.module.scss'

interface Props {
    text: string | undefined;
    style?: CSSProperties;
    className?: string;
    visible?: boolean;
}

const InlineError: FC<Props> = ({text, style = {}, className = '', visible = true}) => {
    if(!visible) return null;

    return <span className={`${styles.container} ${className}`} style={style}>{text}</span>
}

export default InlineError;