import React, {FC, CSSProperties} from "react";
import styles from './styles.module.scss'
import {Button} from "../index";

interface Props {
    placeholder?: string;
    button?: boolean;
    buttonText?: string;
    onButtonClicked?: () => void;

    style?: CSSProperties;
    className?: string;
    onChange?: (e: any) => void;
    value: string;
    onKeyDown?: (e: any) => void;
}

const Input: FC<Props> = ({
    placeholder = 'Enter value',
    onButtonClicked,
    buttonText = 'Add',
    onChange,
    value,
    style = {},
    className = '',
    onKeyDown,
    button = true

}) => {
    return (
        <span className={`${styles.wrapper} ${className}`} style={style}>
            <input placeholder={placeholder} value={value} onChange={onChange} onKeyDown={onKeyDown}/>
            {button && <Button label={buttonText} onClick={onButtonClicked} type='text'/> }
        </span>
    )
}

export default Input;