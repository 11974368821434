import React, {FC} from "react";
import styles from './styles.module.scss'
import {IoClose} from "react-icons/io5";
import {useAppSelector} from "store/hooks";
import {createPortal} from "react-dom";
import useModal from "hooks/useModal";

const Modal: FC = () => {
    const {
        title,
        icon,
        style,
        className,
        children,
        visible,
    } = useAppSelector(state => state.modalHook)

    const {close: closeModal} = useModal();

    return createPortal(
        <div className={`${styles.wrapper} ${visible ? styles.show : ''}`}>
            <div className={styles.mask} onClick={closeModal}/>
            <div className={`${styles.modal} ${className}`} style={style}>
                <div className={styles.header}>
                    <span>{icon}<span>{title}</span></span>
                    <IoClose className={styles.close} onClick={closeModal}/>
                </div>

                <div className={styles.content}>
                    {children}
                </div>
            </div>
        </div>,
        document.body
    )
}

export default Modal;