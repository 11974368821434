import {createSlice} from '@reduxjs/toolkit'
import {CSSProperties, ReactNode} from "react";
interface State {
    title?: string;
    icon?: ReactNode;
    style?: CSSProperties;
    className?: string;
    children?: ReactNode;
    visible?: boolean;
    onClose?: () => void;

}

const initialState: State = {
    title: '',
    icon: undefined,
    style: {},
    className: '',
    children: undefined,
    visible: false,
    onClose: () => {}
};

export const modalSlice = createSlice({
    name: 'modal',
    initialState,

    reducers: {
        showModal: (state: State, action) => {
            state.visible = true;
            state.title = action.payload.title;
            state.icon = action.payload.icon;
            state.className = action.payload.className;
            state.children = action.payload.children;
            state.onClose = action.payload.onClose;
        },
        closeModal: (state: State) => {
            state.visible = false;
            state.title = '';
            state.icon = undefined;
            state.className = '';
            state.children = undefined;
            state.onClose = () => {}
        },
    },

})

export const {showModal, closeModal} = modalSlice.actions;

export default modalSlice.reducer
