import {AuthenticationResponse} from 'gen/endurosat-api'
import envGetter from 'utils/env-variables-getter'
import axios from 'types-axios'
import {AuthenticationData} from "models/authentication-data";
import {decodeToken} from "react-jwt";
import {authenticationAPI} from "./endurosat-api.sevice";
import {AuthLocalStorage} from "./local-storage";
import {CognitoIdentity} from "@aws-sdk/client-cognito-identity";

class AuthenticationService {
    private static instance: AuthenticationService

    public static getInstance: () => AuthenticationService = () => {
        if (!AuthenticationService.instance) {
            AuthenticationService.instance = new AuthenticationService()
        }
        return AuthenticationService.instance
    }

    getAuthURL = () => {
        const {
            oauthBaseUrl,
            oauthResponseType,
            oauthClientId,
            oauthRedirectUri,
            oauthIdentityProvider,
            oauthScope,
        } = envGetter;

        const authUrl = `${oauthBaseUrl}/authorize?response_type=${oauthResponseType}&client_id=${oauthClientId}&redirect_uri=${oauthRedirectUri}&identity_provider=${oauthIdentityProvider}&scope=${oauthScope}`;

        return authUrl
    }

    exchangeCodeForTokens = (code: string): Promise<AuthenticationResponse> => {
        const {
            oauthBaseUrl,
            oauthGrantType,
            oauthClientId,
            oauthRedirectUri,
        } = envGetter;

        const params = new URLSearchParams()
        params.append('redirect_uri', oauthRedirectUri)
        params.append('grant_type', oauthGrantType)
        params.append('client_id', oauthClientId)
        params.append('code', code)

        return axios
            .post(`${oauthBaseUrl}/oauth2/token`, params, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    Accept: '*/*',
                },
            })
            .then((response) => {
                if (response.data) {
                    return Promise.resolve(response.data)
                }
                return Promise.reject('No valid token response')
            })
            .catch((error) => {
                this.invalidateTokens()
                return Promise.reject(error)
            })
    }

    getUserInfoFromAuthIdToken = (): Promise<AuthenticationData> => {
        return new Promise((resolve, reject) => {
            const idToken = AuthLocalStorage.getAuthData()?.id_token

            if (!idToken) {
                return reject('No valid id token in the storage')
            }

            const data: any = decodeToken(idToken)

            resolve(data)
        })
    }

    isAuthAccessTokenExpired = (accessToken: string) => {

        const {exp: expirationTime} = decodeToken(accessToken) as any;

        if (expirationTime) {
            return Date.now() / 1000 > expirationTime
        }

        return true
    }

    refreshTokens = (refreshToken: string): Promise<AuthenticationResponse> => {
        const {oauthClientId} = envGetter

        return authenticationAPI
            .authenticate(
                oauthClientId,
                `refresh_token`,
                undefined,
                undefined,
                refreshToken
            )
            .then((response) => {
                if (response.data) {
                    return Promise.resolve(response.data)
                }
                return Promise.reject('No valid token response')
            })
            .catch((error) => {
                this.invalidateTokens()
                return Promise.reject(error)
            })
    }


    storeAuthTokens = async (data: AuthenticationResponse) => {
        AuthLocalStorage.setAuthData(data)
        await this.getCognitoCredentials();
    }

    invalidateTokens = () => {
        AuthLocalStorage.removeAuthData()
        AuthLocalStorage.removeCognitoCredentials()
    }

    retrieveValidAuthData = (): Promise<AuthenticationResponse> => {
        return new Promise((resolve, reject) => {
            const authData = AuthLocalStorage.getAuthData()

            if (!authData) {
                return reject('No valid authentication data in the storage')
            }

            if (this.isAuthAccessTokenExpired(authData.access_token!)) {
                if (!authData.refresh_token) {
                    return reject('No valid refresh token in the storage')
                }
                this.refreshTokens(authData.refresh_token)
                    .then((data) => {
                        this.storeAuthTokens({
                            ...data,
                            refresh_token: authData.refresh_token,
                        })
                        resolve({
                            access_token: data.access_token,
                            expires_in: data.expires_in,
                            id_token: data.id_token,
                        })
                    })
                    .catch((error) => {
                        reject(error)
                    })
            } else {
                resolve({
                    access_token: authData.access_token,
                    expires_in: authData.expires_in,
                    id_token: authData.id_token,
                })
            }
        })
    }

    getCognitoCredentials = async () => {
        const {identityPoolId, userPoolId, awsRegion} = envGetter;
        const idToken = AuthLocalStorage.getAuthData()?.id_token;

        const logins = {[`cognito-idp.${awsRegion}.amazonaws.com/${userPoolId}`]: idToken!};

        const cognitoIdentity = new CognitoIdentity({region: awsRegion});

        return new Promise((resolve, reject) => {
            try {
                const storeCognitoCredentials = (_: any, data: any) => {
                    if (data) {
                        AuthLocalStorage.setCognitoCredentials(data.Credentials);
                        resolve('Success');
                    }
                };

                const getCredentials = (_: any, data: any) => {
                    if (data && data.IdentityId)
                        cognitoIdentity.getCredentialsForIdentity(
                            {
                                IdentityId: data.IdentityId,
                                Logins: logins,
                            },
                            storeCognitoCredentials
                        );
                };

                cognitoIdentity.getId({IdentityPoolId: identityPoolId, Logins: logins}, getCredentials)
            } catch (err) {
                reject(err);
            }
        })
    }
}

export default AuthenticationService.getInstance()