import axios, { AxiosInstance } from 'axios'
import { Configuration, AuthenticationApi } from 'gen/endurosat-api'
import envGetter from 'utils/env-variables-getter'
import authenticationService from './authentication.service'

class EnduroSatApiService {
    public static satellitesAPI: any
    private static axiosInstance: AxiosInstance

    public static getApiInstance(
        localAPI: any,
        generatedApi: any,
        config: any = this.authenticatedApiConfig()
    ) {
        if (!localAPI) {
            localAPI = new generatedApi(
                config,
                envGetter.groundStationApiUri,
                this.getAxiosInstance()
            )
        }
        return localAPI
    }

    private static authenticatedApiConfig: () => Configuration = () =>
        new Configuration({
            accessToken: EnduroSatApiService.getValidAccessToken,
            basePath: envGetter.groundStationApiUri,
        })

    public static unauthenticatedApiConfig: () => Configuration = () =>
        new Configuration({
            basePath: envGetter.groundStationApiUri,
        })

    private static getAxiosInstance = () => {
        if (!EnduroSatApiService.axiosInstance) {
            EnduroSatApiService.axiosInstance = axios.create({})
        }
        return EnduroSatApiService.axiosInstance
    }

    private static getValidAccessToken = () =>
        authenticationService.retrieveValidAuthData().then((auth) => {
            if (auth.access_token) {
                return auth.access_token
            } else {
                throw new Error('No access token provided')
            }
        })
}

export const authenticationAPI: AuthenticationApi = EnduroSatApiService.getApiInstance(
    EnduroSatApiService.satellitesAPI,
    AuthenticationApi,
    EnduroSatApiService.unauthenticatedApiConfig()
)