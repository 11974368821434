import {useAppDispatch} from "store/hooks";
import {showModal, closeModal} from "store/customHooks/modal";
import {CSSProperties, ReactNode} from "react";

interface IModal {
    title?: string;
    icon?: ReactNode;
    style?: CSSProperties;
    className?: string;
    children?: ReactNode;
    visible?: boolean;
    onClose?: () => void;
}

function useMessage() {
    const dispatch = useAppDispatch();

    const show = ({title, icon, style, className, children, visible, onClose}: IModal) => {
        dispatch(showModal({title, icon, style, className, children, visible, onClose}));
    }

    const close = () => {
        dispatch(closeModal())
    }

    return {show, close};
}

export default useMessage;
