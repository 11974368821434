import {createSlice, current} from '@reduxjs/toolkit'
import {Module} from "models";


interface State {
    modules: Module[]
}

const initialState: State = {
    modules: [] as Module[]
};

export const orderSlice = createSlice({
    name: 'order',
    initialState,

    reducers: {
        removeModuleFromOrder: (state: State, action) => {
            state.modules = current(state).modules.filter((m, i) => i !== action.payload);
        },
        addModuleToOrder: (state: State, action) => {
            state.modules = [...state.modules, action.payload];
        },
        setModules: (state: State, action) => {
            state.modules = action.payload;
        },
    },
})

// Action creators are generated for each case reducer function
export const {removeModuleFromOrder, addModuleToOrder, setModules} = orderSlice.actions

export default orderSlice.reducer
