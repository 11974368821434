import React, {CSSProperties, FC} from "react";
import styles from './styles.module.scss'

interface CircledNumberProps {
    n: number;

    style?: CSSProperties;
    className?: string;
}

const CircledNumber: FC<CircledNumberProps> = ({n, style = {}, className = ''}) => (
    <span className={`${styles.circledNumber} ${className}`} style={style}>{n}</span>
)

export default CircledNumber