import React, {FC} from "react";
import styles from './Spinner.module.scss'
import {ImSpinner2} from 'react-icons/im'

interface Props {
    className?: string;
}

const Spinner: FC<Props> = ({className}) => {
    return (
        <div className={`${styles.container} ${className || ''}`}>
            <ImSpinner2/>
        </div>
    )
}

export default Spinner
